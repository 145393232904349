import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";

import TestPage from "../view/dashboard/test/TestView.vue";
import successHotelBook from "../view/dashboard/booking/success_Hotel_Book.vue";
import PermissionDenied from "../view/dashboard/staticPages/403.vue";

import newOrganization from "@/view/dashboard/news/index.vue";
// import auth
import loginPage from "../components/auth/login.vue";
import forgetPage from "../components/auth/forget.vue";
import verificationCodePage from "../components/auth/verification_code.vue";
import resetPasswordPage from "../components/auth/reset_password.vue";

//import Dashboard
import Dashboard from "../view/dashboard/dashboard.vue";
import Website from "../view/website/website.vue";
import HomePage from "../view/home.vue";
import CreatePage from "../view/dashboard/Policy/CreatePage.vue";
import NewHome from "../view/dashboard/New_Home/dashBoard.vue";

//import Umrah Package
// import AccountsBanks from "@/components/clients/AccountsBanks.vue";

import bookUmrah from "../view/dashboard/Umrah Package/bookUmrah.vue";
import editUmrah from "../view/dashboard/Umrah Package/bookUmrah.vue";
import nationalityPacakgePrice from "../view/dashboard/Umrah Package/nationalityPacakgePrice.vue";
import nationalityPacakgePriceEdit from "../view/dashboard/Umrah Package/nationalityPacakgePrice.vue";
import packageNationality from "../view/dashboard/Umrah Package/package_nationality.vue";
import umrahPackageTable from "../view/dashboard/Umrah Package/umrahPackageTable.vue";
import tripPrograme from "../view/dashboard/Umrah Package/tripPrograme.vue";
import umrahBookDetails from "../view/dashboard/Umrah Package/bookDetails.vue";
//import organization
import FormOrganization from "../view/dashboard/organization/formOrganization.vue";
import TreeOrganization from "../view/dashboard/organization/tree.vue";
import editOrganization from "../view/dashboard/organization/editOrganization.vue";
import detailsOrganization from "../view/dashboard/organization/detailsOrganization.vue";
import subOrganization from "../view/dashboard/organization/subOrganization.vue";
import setOrganizationprovider from "../view/dashboard/organization/setOrganizationprovider.vue";

import organizationHotelsAdd from "@/view/dashboard/organization/organizationHotelsAdd.vue";
import organizationHotels from "@/view/dashboard/organization/organizationHotels.vue";

// import b2cusers
import b2cUsers from "../view/dashboard/users/b2cUsers.vue";
import booksPackage from "../view/dashboard/booksPackage.vue";

import bookPending from "../view/dashboard/bookPending/bookPending.vue";

// import b2cusers
import b2cUserFlight from "../view/dashboard/flight-client/b2cUserFlight.vue";
import b2cUserFlightDetails from "../view/dashboard/flight-client/b2cUserFlightDetails";

//import empolyment
import AllEmpolyment from "../view/dashboard/empolyment/show.vue";
import employeeLogs from "../view/dashboard/employeeLogs/show.vue";
import evidenceLimitations from "../view/dashboard/evidenceLimitations/index.vue";
import AddEmpolyment from "../view/dashboard/empolyment/add.vue";
import EditEmpolyment from "../view/dashboard/empolyment/edit.vue";

//import receipt
import AllReceipt from "../view/dashboard/receipt/show.vue";
import AddReceipt from "../view/dashboard/receipt/add.vue";
import EditReceipt from "../view/dashboard/receipt/edit.vue";
import CopiedReceipt from "@/view/dashboard/receipt/CopiedReceipt.vue";

//import expenss
import AllExpensess from "../view/dashboard/expenses/show.vue";
import AddExpensess from "../view/dashboard/expenses/add.vue";
import EditExpensess from "../view/dashboard/expenses/edit.vue";

//import revnue
import AllRevenue from "../view/dashboard/revenue/show.vue";
import AddRevenue from "../view/dashboard/revenue/add.vue";
import EditRevenue from "../view/dashboard/revenue/edit.vue";

//import exchange
import AllExchange from "../view/dashboard/exchange/show.vue";
import AddExchange from "../view/dashboard/exchange/add.vue";
import EditExchange from "../view/dashboard/exchange/edit.vue";

//import provider
import AllProvider from "../view/dashboard/provider/show.vue";
import AllCredential from "../view/dashboard/provider/showCredential.vue";
import AddProvider from "../view/dashboard/provider/add.vue";
import EditProvider from "../view/dashboard/provider/edit.vue";
import AddProviderCredintil from "../view/dashboard/provider/add-Credintial.vue";

//import construct
import AllConstructor from "../view/dashboard/dailyConstructor/show.vue";
import AddConstructor from "../view/dashboard/dailyConstructor/add.vue";

//import construct
import Contract from "../view/dashboard/contract/show.vue";
import ContractRoom from "../view/dashboard/contract/room.vue";
import AddContract from "../view/dashboard/contract/add.vue";
import editContract from "../view/dashboard/contract/edit.vue";
import ContractReservations from "../view/dashboard/contract/reservations.vue";
import DayStock from "@/view/dashboard/contract/dayStock.vue";

//import seasons
import showSeason from "../view/dashboard/contract/seasons/show.vue";
import addSeason from "../view/dashboard/contract/seasons/add.vue";
import editSeason from "../view/dashboard/contract/seasons/edit.vue";
import editSeasonsMeals from "../view/dashboard/contract/seasons/editMeals.vue";
import rateCodeContracrt from "../view/dashboard/contract/seasons/rateCode.vue";
import editRateCode from "../view/dashboard/contract/seasons/editRateCode.vue";
import rateCodeContracrtHistory from "../view/dashboard/contract/seasons/history.vue";
import roomBooks from "../view/dashboard/contract/seasons/roomBooks.vue";
import DayStockHistory from "../view/dashboard/contract/dayStockHistory.vue";
//import safe
import AllSafes from "../view/dashboard/safe/show.vue";
import AddSafes from "../view/dashboard/safe/add.vue";
import EditSafes from "../view/dashboard/safe/edit.vue";
import softDetails from "../view/dashboard/safe/details.vue";
import AllTransfers from "../view/dashboard/safe/AllTransfers.vue";
import addTransfer from "../view/dashboard/safe/addTransfer.vue";
import showTransfer from "../view/dashboard/safe/showTransfer.vue";

//import accounts
import bankAccount from "../view/dashboard/accounts/bankAccount.vue";
import moneyListCenter from "../view/dashboard/accounts/moneyListCenter.vue";
import moneyListCenterChild from "../view/dashboard/accounts/moneyListCenterChild.vue";
import AddAccount from "../view/dashboard/accounts/add.vue";
import editAccount from "../view/dashboard/accounts/edit.vue";
import AccountDetails from "../view/dashboard/accounts/details.vue";
import accountStatement from "../view/dashboard/accounts/accountStatement.vue";
import general_account_statement from "../view/dashboard/accounts/generalAccountStatement.vue";

//import treeBranches
import treeBranches from "../view/dashboard/treeBranches/treeBranches.vue";
import detialsBranch from "../view/dashboard/treeBranches/detialsBranch.vue";
import processCostCenter from "../view/dashboard/treeBranches/processCostCenter.vue";
import openingBalance from "../view/dashboard/treeBranches/openingBalance.vue";
import whatsappConfig from "../view/dashboard/whatsAppConfig.vue";
import emailConfig from "../view/dashboard/Email_setting/emailConfiguration.vue";
import studentNote from "../view/dashboard/treeBranches/studentNote.vue";

//imoprt wallets
import walletPage from "../view/dashboard/wallet/wallet.vue";

//import room
import allRooms from "../view/dashboard/room/showRooms.vue";
import addRooms from "../view/dashboard/room/addRoom.vue";
import editRoom from "../view/dashboard/room/editRoom.vue";

//import view
import allViews from "../view/dashboard/room/viewType/allViews.vue";
import editView from "../view/dashboard/room/viewType/editView.vue";

//import bed
import allBeds from "../view/dashboard/room/bedType/allBeds.vue";
import editBed from "../view/dashboard/room/bedType/editBed.vue";

//import meal
import allMeals from "../view/dashboard/room/mealType/allMeals.vue";
import editMeal from "../view/dashboard/room/mealType/editMeal.vue";

//import Suppliers
import showSuppliers from "../view/dashboard/suppliers/index.vue";
import addSuppliers from "../view/dashboard/suppliers/add.vue";
import editSuppliers from "../view/dashboard/suppliers/edit.vue";
import detailsSuppliers from "../view/dashboard/suppliers/details.vue";

//import clients
import showClients from "../view/dashboard/clients/index.vue";
import addClients from "../view/dashboard/clients/add.vue";
import editClients from "../view/dashboard/clients/edit.vue";
import clientsDetails from "../view/dashboard/clients/details.vue";
import porralUser from "../view/dashboard/Portal/UserPortal.vue";

//import facilities
import allFacilities from "../view/dashboard/room/facilities/allFacilities.vue";
import editFacilities from "../view/dashboard/room/facilities/editFacilities.vue";

//import countries
import AllCountries from "../view/dashboard/country/show.vue";
import addCountry from "../view/dashboard/country/add.vue";
import editCountry from "../view/dashboard/country/edit.vue";

//import cities
import allCities from "../view/dashboard/city/show.vue";
import addCities from "../view/dashboard/city/add.vue";
import editCities from "../view/dashboard/city/edit.vue";

//import hotels
import allHotels from "../view/dashboard/hotel/show.vue";
import addHotel from "../view/dashboard/hotel/add.vue";
import editHotel from "../view/dashboard/hotel/editHotel.vue";
import revenues from "../view/dashboard/hotel/revenues.vue";
import duringPeriod from "../view/dashboard/hotels/duringPeriod.vue";

// import header text
import HeaderText from "../view/dashboard/header_text/HeaderTextAdd.vue";
// import header text

//imoprt hotels
import hotelsPage from "../view/dashboard/hotels/index.vue";
import hotelsPageNew from "../view/dashboard/hotels/indexNew.vue";
import hotelsDetailsPage from "../view/dashboard/hotels/details.vue";

//imoprt book
import newBookPage from "../view/dashboard/booking/newHotelBook.vue";
import newCollectionBookPage from "../view/dashboard/booking/newCollection.vue";
import bookPage from "../view/dashboard/booking/book.vue";

// import bookFlight
import bookFlight from "../view/dashboard/bookFlight/bookFlight.vue";
import successFlightbook from "../view/dashboard/bookFlight/successFlightbook.vue";

// import flightoffline
import flightOffline from "../view/dashboard/flightOffline/show.vue";
import flightBooking from "../view/dashboard/flightOffline/book.vue";
import editFlightBooking from "../view/dashboard/flightOffline/book.vue";
import flightPdf from "../view/dashboard/flight-client/flightPdf.vue";

// import setup
import Setup from "../view/setup/setup.vue";
import WelcomePage from "../view/setup/welcome.vue";
import SettingPage from "../view/setup/setting.vue";
import feesSetup from "../view/setup/setupFees/setup.vue";
import editCustomSetupFees from "../view/setup/setupFees/editCustomizeSetup.vue";

// import books
// import bookShow from "@/view/dashboard/books/bookShow.vue";
// import salePone from "@/view/dashboard/books/salePone.vue";

// import points
// import showPoints from "../view/dashboard/points/showPoints.vue";
// import coponsDetails from "../view/dashboard/points/coponsDetails.vue";
import AddPoints from "../view/dashboard/points/AddPoints.vue";
import EditPoints from "../view/dashboard/points/AddPoints.vue";

// Gifts
import Gifts from "../view/dashboard/Gifts/Gifts.vue";
import AddGifts from "../view/dashboard/Gifts/AddGifts.vue";
import EditGifts from "../view/dashboard/Gifts/AddGifts.vue";

// theme
import InvoiceTheme from "../view/dashboard/invoiceTheme/InvoiceTheme.vue";
import AddInvoiceTheme from "../view/dashboard/invoiceTheme/AddInvoiceTheme.vue";
import EditInvoiceTheme from "../view/dashboard/invoiceTheme/updateinvoiceTheme.vue";

// stage-settings
import stageSetting from "../view/dashboard/stageSetting/stageSetting.vue";
import stageShow from "../view/dashboard/stageSetting/stageShow.vue";
import Affiliates from "../view/dashboard/stageSetting/Affiliates.vue";
import affiliatesAdd from "../view/dashboard/stageSetting/stageTitle.vue";
import affiliatesEdit from "../view/dashboard/stageSetting/stageTitle.vue";
import bookCount from "../view/dashboard/stageSetting/bookCount.vue";
import markters from "../view/dashboard/stageSetting/markters.vue";
import marktersAdd from "../view/dashboard/stageSetting/marktersAdd.vue";
import marktersEdit from "../view/dashboard/stageSetting/marktersAdd.vue";

// purchasesBill
import purchasesBill from "../view/dashboard/purchasesBill/purchasesBill.vue";
import purchasesBillAdd from "../view/dashboard/purchasesBill/purchasesBillAdd.vue";
import purchasesBillShow from "../view/dashboard/purchasesBill/purchasesBillShow.vue";

// import booking offline
import hotelsBookOffline from "../view/dashboard/hotelsBookOffline/hotelsBookOffline.vue";
import updateHotelsBookOffline from "../view/dashboard/hotelsBookOffline/hotelsBookOffline.vue";
import HotelsBookOfflineHistory from "../view/dashboard/hotelsBookOffline/HotelsBookOfflineHistory.vue";
import offlineBookHotelsIndex from "../view/dashboard/hotelsBookOffline/index.vue";
import portalBookHotelsIndex from "../view/dashboard/hotelsBookOffline/portalBookHotelsIndex.vue";
import hotelsPaymentMethds from "../view/dashboard/hotelsBookOffline/paymentMethods.vue";
import hotelsAttachmentTable from "../view/dashboard/hotelsBookOffline/attachmentTable.vue";
import hotelsAttachmentAdd from "../view/dashboard/hotelsBookOffline/addAttachment.vue";
import hotelsAttachmentEdit from "../view/dashboard/hotelsBookOffline/addAttachment.vue";
import bookOfflinePage from "../view/dashboard/offline/book.vue";
import bookTablePage from "../view/dashboard/offline/index.vue";
import PostToAccount from "../view/dashboard/offline/post_to_account.vue";
import allBookTablePage from "../view/dashboard/offline/index.vue";
import historyBookPage from "../view/dashboard/offline/history.vue";
import editBookPage from "../view/dashboard/offline/edit.vue";
import showBookPage from "../view/dashboard/offline/show.vue";
import paidHotels from "../view/dashboard/offline/paid.vue";
import clientHistory from "../view/dashboard/users/clientHistory.vue";
// import pillHotels from "../view/dashboard/offline/pill.vue";
import invoiceOffline from "../view/dashboard/offline/invoice.vue";
import newInvoiceOffline from "../view/dashboard/offline/Newinvoice.vue";
import newTaxInvoiceArOffline from "../view/dashboard/offline/TaxNewinvoiceAr.vue";
import newTaxInvoiceEnOffline from "../view/dashboard/offline/TaxNewinvoiceEn.vue";

import addPaidHotels from "../view/dashboard/offline/addPaid.vue";
import roomingListPage from "../view/dashboard/hotels/roomingListReport.vue";
import arrivalPage from "../view/dashboard/offline/arrival.vue";

// import airport
import flightPage from "../view/dashboard/flight/show.vue";
import flightDetials from "../view/dashboard/flight/detials.vue";
import hotelView from "../view/dashboard/hotels/view.vue";
import pillsHotel from "../view/dashboard/hotels/pills.vue";
import ReportFlight from "../view/dashboard/flight/reportFlights.vue";

//contract
import flightContractIndex from "../view/dashboard/flight/contract/index.vue";
import flightContractAdd from "../view/dashboard/flight/contract/add.vue";
import flightContractEdit from "../view/dashboard/flight/contract/edit.vue";

//seasons
import flightSeasonsIndex from "../view/dashboard/flight/seasons/index.vue";
import flightSeasonsAdd from "../view/dashboard/flight/seasons/add.vue";
import flightSeasonsEdit from "../view/dashboard/flight/seasons/edit.vue";

// rateCodes
import flightRateCodesIndex from "../view/dashboard/flight/rateCode/index.vue";
import flightRateCodesAdd from "../view/dashboard/flight/rateCode/add.vue";

// import airport
import airportIndex from "../view/dashboard/cardFlight/index.vue";
import airportAdd from "../view/dashboard/cardFlight/add.vue";
import airportEdit from "../view/dashboard/cardFlight/edit.vue";

// import flight compnies
import flightCompanyIndex from "../view/dashboard/flightCompnies/index.vue";
import flightCompanyAdd from "../view/dashboard/flightCompnies/add.vue";
import flightCompanyEdit from "../view/dashboard/flightCompnies/edit.vue";

// import reservation
import reservationDetails from "../view/dashboard/reservation/details.vue";
import reservationShow from "../view/dashboard/reservation/show.vue";

// import nationality
import nationalityIndex from "../view/dashboard/nationality/show.vue";
import nationalityAdd from "../view/dashboard/nationality/add.vue";
import nationalityEdit from "../view/dashboard/nationality/edit.vue";

import servicesWebsite from "../view/website/services/index.vue";
import servicesWebsiteAdd from "../view/website/services/add.vue";
import servicesWebsiteEdit from "../view/website/services/add.vue";

import WebsiteSetting from "../view/website/setting/add.vue";
import WebsiteHeaderImages from "../view/website/setting/headers.vue";
import overPrice from "../view/website/over_price/add.vue";
import paymentFees from "../view/website/over_price/payment_fees.vue";

import servicesDashboard from "../view/dashboard/services/show.vue";
import servicesDashboardAdd from "../view/dashboard/services/add.vue";
import servicesDashboardEdit from "../view/dashboard/services/edit.vue";

import visitWebsite from "../view/website/places/index.vue";
import visitWebsiteAdd from "../view/website/places/add.vue";
import visitWebsiteEdit from "../view/website/places/add.vue";

import blogsWebsite from "../view/website/blogs/index.vue";
import blogsWebsiteAdd from "../view/website/blogs/add.vue";
import blogsWebsiteEdit from "../view/website/blogs/add.vue";
import webTheme from "../view/dashboard/organization/webThemes.vue";

import hastagBlogs from "@/view/website/blogs/hashTagBlog.vue";
import addHashtagBlogs from "@/view/website/blogs/addHashtagBlogs.vue";
import editHashtagBlogs from "@/view/website/blogs/addHashtagBlogs.vue";
import categoreyBlogs from "@/view/website/blogs/categoryBlog.vue";
import addcategoryBlogs from "@/view/website/blogs/addcategoryBlogs.vue";
import editcategoryBlogs from "@/view/website/blogs/addcategoryBlogs.vue";

import clientOpinion from "../view/website/client_opinion/index.vue";
import clientOpinionAdd from "../view/website/client_opinion/add.vue";
import clientOpinionEdit from "../view/website/client_opinion/add.vue";

import addSection from "../view/website/app_sections/addSection.vue";
import editSection from "../view/website/app_sections/addSection.vue";
import appSection from "../view/website/app_sections/update.vue";
import indexSection from "../view/website/app_sections/index.vue";
import packageSections from "../view/website/package_Section/index.vue";
import AddpackageSections from "../view/website/package_Section/Add_Section.vue";
import EditpackageSections from "../view/website/package_Section/Add_Section.vue";

// import webFeatures from "../view/website/web_features/index.vue";
import webFeaturesAdd from "../view/website/web_features/add.vue";
// import webFeaturesEdit from "../view/website/web_features/add.vue";

import webStatuses from "../view/website/web_statuses/index.vue";
import webStatusesAdd from "../view/website/web_statuses/add.vue";
import webStatusesEdit from "../view/website/web_statuses/add.vue";

//payment

import paymentIndex from "../view/dashboard/payment/show.vue";
import paymentAdd from "../view/dashboard/payment/add.vue";
import paidData from "../view/dashboard/paid/paid.vue";

//invoice
import invoicesFirst from "../view/dashboard/invoices/AMENDMENT.vue";

//import about
import aboutSecondAdd from "../view/website/about/add.vue";
import aboutHeader from "../view/website/about/headerAbout.vue";
import aboutSecondIndex from "../view/website/about/secondSection.vue";
import aboutSecondEdit from "../view/website/about/edit.vue";

// import visa
import allVisa from "../view/dashboard/visaTypes/show.vue";
import addVisa from "../view/dashboard/visaTypes/add.vue";
import editVisa from "../view/dashboard/visaTypes/edit.vue";
import allContracts from "../view/dashboard/visaTypes/allContracts.vue";
import addVisaContract from "../view/dashboard/visaTypes/contract.vue";
import editVisaContract from "../view/dashboard/visaTypes/editContract.vue";
import allVisaSeasons from "../view/dashboard/visaTypes/visa_seasons/show.vue";
import addVisaSeason from "../view/dashboard/visaTypes/visa_seasons/add.vue";
import editVisaSeason from "../view/dashboard/visaTypes/visa_seasons/edit.vue";
import addRateCode from "../view/dashboard/visaTypes/addRateCode.vue";
import editVisaRateCode from "../view/dashboard/visaTypes/editRateCode.vue";
import allRateCode from "../view/dashboard/visaTypes/allRateCode.vue";

//package_star_category
import pagckageStarCategoryAdd from "../view/dashboard/package_star_category/pagckageStarCategoryAdd.vue";
import pagckageStarCategoryEdit from "../view/dashboard/package_star_category/pagckageStarCategoryAdd.vue";
import packageStarCategory from "../view/dashboard/package_star_category/pagckageStarCategory.vue";
//package_star_category

// flight Routes
import flightCompanies from "../view/website/flightRoutes/flightCompanies.vue";
import flightCompaniesIndex from "../view/website/flightRoutes/index.vue";
import flightCompaniesEdit from "../view/website/flightRoutes/edit.vue";
import flightCompaniesRoutes from "../view/website/flightRoutes/routes.vue";

//import about
import clientAdentage from "../view/website/clientAdventages/show.vue";
import clientAdentageAdd from "../view/website/clientAdventages/add.vue";
import clientAdentageEdit from "../view/website/clientAdventages/edit.vue";

//transporter
import carType from "../view/dashboard/transporter/carType/index.vue";
import carTypeEdit from "../view/dashboard/transporter/carType/edit.vue";
import carTypeAdd from "../view/dashboard/transporter/carType/add.vue";
import carCat from "../view/dashboard/transporter/carCategory/index.vue";
import carCatEdit from "../view/dashboard/transporter/carCategory/edit.vue";
import carCatAdd from "../view/dashboard/transporter/carCategory/add.vue";
import routersAdd from "../view/dashboard/transporter/routers/add.vue";
import routersEdit from "../view/dashboard/transporter/routers/edit.vue";
import routersIndex from "../view/dashboard/transporter/routers/index.vue";

import contractTransportAdd from "../view/dashboard/transporter/contract/add.vue";
import contractTransportIndex from "../view/dashboard/transporter/contract/index.vue";
import contractTransportEdit from "../view/dashboard/transporter/contract/edit.vue";

import seasonTransportAdd from "../view/dashboard/transporter/seasons/add.vue";
import seasonTransportIndex from "../view/dashboard/transporter/seasons/index.vue";
import seasonTransportEdit from "../view/dashboard/transporter/seasons/edit.vue";

import rateCodeTransport from "../view/dashboard/transporter/rateCode/index.vue";
import AddrateCodeTransport from "../view/dashboard/transporter/rateCode/add.vue";
import editrateCodeTransport from "../view/dashboard/transporter/rateCode/edit.vue";

import contactWebsite from "../view/website/contact_us/index.vue";
import policyWebsite from "../view/website/policy/index.vue";
import termsWebsite from "../view/website/terms/index.vue";

// faqs
import faqsIndex from "../view/website/faqs/index.vue";
import faqsAdd from "../view/website/faqs/add.vue";
import faqsEdit from "../view/website/faqs/add.vue";

// coupons
import addCoupon from "../view/website/coupons/add.vue";
import editCoupon from "../view/website/coupons/add.vue";
import couponsShow from "../view/website/coupons/couponsShow.vue";

//revenue reports
import BookingProfit from "../view/dashboard/BookingProfit.vue";
import FlightRevenue from "../view/dashboard/revenuesReports/flightsRevenue/index.vue";
import PackageRevenue from "../view/dashboard/revenue/PackageRevenues.vue";
import salesReports from "@/view/dashboard/reports/salesReports.vue";
import TaxReportsDue from "@/view/dashboard/reports/TaxReportsDue.vue";
import purchaseReports from "@/view/dashboard/reports/purchaseReports.vue";
import HotelAvailability from "@/view/dashboard/reports/HotelAvailability.vue";

// Motion detection
import MovementDetection from "../view/dashboard/MotionDetection/MovementDetection.vue";

import umrahPackage from "../view/dashboard/umrahPackage/add.vue";
import InsuranceCompaniesAdd from "../view/dashboard/InsuranceCompanies/InsuranceCompaniesAdd.vue";
import suplierTypeAdd from "../view/dashboard/supliers/suplierTypeAdd.vue";
import suplierTypeEdit from "../view/dashboard/supliers/suplierTypeAdd.vue";
import suplierType from "../view/dashboard/supliers/suplierType.vue";
import clientTypeAdd from "../view/dashboard/client_type/clientTypeAdd.vue";
import clientTypeEdit from "../view/dashboard/client_type/clientTypeAdd.vue";
import clientTypePortalAdd from "../view/dashboard/client_type_portal/clientTypePortalAdd.vue";
import clientTypePortalEdit from "../view/dashboard/client_type_portal/clientTypePortalAdd.vue";
import clientTypePortal from "../view/dashboard/client_type_portal/clientTypePortal.vue";
import clientType from "../view/dashboard/client_type/clientType.vue";
import InsuranceCompaniesEdit from "../view/dashboard/InsuranceCompanies/InsuranceCompaniesAdd.vue";
import InsuranceCompanies from "../view/dashboard/InsuranceCompanies/InsuranceCompanies.vue";
import DiscountType from "../view/dashboard/DiscountType/DiscountType.vue";
import DiscountTypeAdd from "../view/dashboard/DiscountType/DiscountTypeAdd.vue";
import DiscountTypeEdit from "../view/dashboard/DiscountType/DiscountTypeAdd.vue";
import InsuranceTypeAdd from "../view/dashboard/InsuranceCompanies/InsuranceTypeAdd.vue";
import InsuranceTypeEdit from "../view/dashboard/InsuranceCompanies/InsuranceTypeAdd.vue";
import InsuranceType from "../view/dashboard/InsuranceCompanies/InsuranceType.vue";

// hotelRevenues
import hotelRevenues from "../view/dashboard/hotelRevenues/hotelRevenues.vue";

import paymentVisis from "../view/dashboard/payment/paymentVisis.vue";

// package type
import addPackage from "../view/dashboard/packageType/add.vue";
import editPackageType from "../view/dashboard/packageType/add.vue";
import allPackageTypes from "../view/dashboard/packageType/index.vue";

// penndinMarkters
import penndinMarkters from "../view/dashboard/penndinMarkters/penndinMarkters.vue";
import penddingPoints from "../view/dashboard/pendingPoints/penddingPoints.vue";
// penndinMarkters

//currency
import addCurrency from "../view/website/currency/addCurrency.vue";
import currencyIndex from "../view/website/currency/currencyIndex.vue";
import editCurrency from "../view/website/currency/addCurrency.vue";
import defaultCurrency from "../view/website/currency/defaultCurrency";

// import arrival_Guests
import arrivalGuestsB2b from "../view/dashboard/arrivalGuests/arrivalGuestsB2b.vue";
import arrivalGuestsB2c from "../view/dashboard/arrivalGuests/arrivalGuestsB2c.vue";
import roomGuestsB2b from "../view/dashboard/arrivalGuests/roomGuestsB2b.vue";
import roomGuestsB2c from "../view/dashboard/arrivalGuests/roomGuestsB2c.vue";

// permission
import permission from "@/permission/permission.vue";
import permissiondetails from "@/permission/permission.vue";
import permissionrole from "@/permission/permissionrole.vue";

//loaders
import dynamicLoader from "../view/website/loaders/dynamicLoader.vue";

// hotel offline
import offlineHotelDetails from "../view/dashboard/hotelOffline/hotelDetails.vue";
import addOfflinehotel from "../view/dashboard/hotelOffline/addHotel.vue";
import editOfflinehotel from "../view/dashboard/hotelOffline/addHotel.vue";
import reportHotel from "../view/dashboard/hotel/hotelReport.vue";
import roomimgListReport from "../view/dashboard/hotels/roomingListReport.vue";

// email setting

import AddEmail from "../view/dashboard/Email_setting/AddEmail.vue";
import AddPortalEmail from "../view/dashboard/Email_setting/AddPortalEmail.vue";

// attachment type
import addAttachmentType from "../view/dashboard/attachments/hotel/type/add.vue";
import editAttachmentType from "../view/dashboard/attachments/hotel/type/add.vue";
import allAttachmentType from "../view/dashboard/attachments/hotel/type/index.vue";
// hotel attachment
import addHotelAttachment from "../view/dashboard/attachments/hotel/amenity/add.vue";
import editHotelAttachment from "../view/dashboard/attachments/hotel/amenity/edit.vue";
import allHotelAttachment from "../view/dashboard/attachments/hotel/amenity/index.vue";

// popUp msg
import addPopUpMsg from "../view/dashboard/popUp_msg/add.vue";
import PopUpDisplay from "../view/dashboard/popUp_msg/displayMethod.vue";

// cost center
import addCostCenter from "../view/dashboard/cost_center/add.vue";
import editCostCenter from "../view/dashboard/cost_center/add.vue";
import costCenterIndex from "../view/dashboard/cost_center/index.vue";

//add exxhange or recipet
import addExchangeOrRecipet from "@/view/dashboard/exchangeRecipt/addExchangeReceipt.vue";
import showExchangeOrRecipet from "../view/dashboard/exchangeRecipt/showExchangeReceipt.vue";

// meta data
import addMeta from "../view/dashboard/meta_data/add.vue";
import editMeta from "../view/dashboard/meta_data/add.vue";
import metaIndex from "../view/dashboard/meta_data/index.vue";

// banner
import addBanner from "../view/dashboard/banner/add.vue";
import editBanner from "../view/dashboard/banner/add.vue";
import allBanners from "../view/dashboard/banner/index.vue";

// terms and conditions
import addTerms from "../view/dashboard/terms_conditions/add.vue";
import editTerms from "../view/dashboard/terms_conditions/add.vue";
import termsIndex from "../view/dashboard/terms_conditions/index.vue";

// sales analysis
import salesAnalysis from "../view/dashboard/clients/analysis.vue";
import salesAnalysisWithDates from "../view/dashboard/clients/analysis.vue";

//room view global
import addRoomView from "../view/dashboard/room/generalRoomViews/addRoomvView.vue";
import roomViewGlobal from "../view/dashboard/room/generalRoomViews/roomViewTable.vue";
import editroomViewGlobal from "../view/dashboard/room/generalRoomViews/addRoomvView.vue";

//excel sheet
import ExcelClient from "../view/dashboard/excel/AddClientExcel.vue";
import ExcelSupplier from "../view/dashboard/excel/AddSupplierExcel.vue";
import ClientTypeExcel from "@/view/dashboard/excel/AddClientTypeExcel.vue";
import SupplierTypeExcel from "@/view/dashboard/excel/AddSupplierTypeExcel.vue";
import DiscountTypeExcel from "@/view/dashboard/excel/AddDiscountExcel.vue";
import paidSupplier from "@/view/dashboard/offline/paidSupplier.vue";

//multi reservation
import confirmReservation from "../view/dashboard/multipleReservations/confirmReservations.vue";
import pendingReservation from "../view/dashboard/multipleReservations/pendingReservations.vue";
//import Financial Center
import FinancialCenter from "@/view/dashboard/MotionDetection/FinancialCenter.vue";
import incomeList from "@/view/dashboard/MotionDetection/incomeList.vue";

//portal setting
import portalSetting from "@/view/website/setting/portalSetting.vue";
import portalWallet from "@/view/dashboard/Portal/PortalWallet.vue";

//book offline service
import bookOfflineService from "../view/dashboard/bookOfflineService/ServiceBookOffline.vue";
import ServiceBookOfflineIndex from "../view/dashboard/bookOfflineService/ServiceBookOfflineIndex.vue";

import AddHomeHotels from "@/view/dashboard/home_hotels/AddHomeHotels.vue";
import CollectedInvoices from "@/view/dashboard/invoices/CollectedInvoices.vue";
import CollectedInvoicesIndex from "@/view/dashboard/invoices/CollectedInvoicesIndex.vue";
import CollectedInvoiceShow from "@/view/dashboard/invoices/CollectedInvoiceShow.vue";
const routes = [
  // Website routes
  {
    path: "/login",
    component: loginPage,
    name: "login",
    meta: { guest: true },
  },
  {
    path: "/forget",
    component: forgetPage,
    name: "forget",
    meta: { guest: true },
  },
  {
    path: "/verification_code",
    component: verificationCodePage,
    name: "verification_code",
    meta: { guest: true },
  },
  {
    path: "/reset_password",
    component: resetPasswordPage,
    name: "reset_password",
    meta: { guest: true },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
    name: "Dashboard",
    children: [
      {
        path: "/add-excel/client",
        component: ExcelClient,
        name: "ExcelClient",
      },
      {
        path: "/add-home-hotels",
        component: AddHomeHotels,
        name: "AddHomeHotels",
      },
      {
        path: "/hotel-availability",
        component: HotelAvailability,
        name: "HotelAvailability",
      },
      {
        path: "/add/portal-setting",
        component: portalSetting,
        name: "portalSetting",
      },
      {
        path: "/portal-wallet",
        component: portalWallet,
        name: "portalWallet",
      },
      {
        path: "/add-excel/supplier",
        component: ExcelSupplier,
        name: "ExcelSupplier",
      },
      {
        path: "/collect-invoices",
        component: CollectedInvoices,
        name: "CollectedInvoices",
      },
      {
        path: "/collect-invoices-index",
        component: CollectedInvoicesIndex,
        name: "CollectedInvoicesIndex",
      },
      {
        path: "/collected-invoice/:id",
        component: CollectedInvoiceShow,
        name: "CollectedInvoiceShow",
      },
      {
        path: "/add-excel/client-type",
        component: ClientTypeExcel,
        name: "ClientTypeExcel",
      },
      {
        path: "/add-excel/supplier-type",
        component: SupplierTypeExcel,
        name: "SupplierTypeExcel",
      },
      {
        path: "/add-excel/discount-type",
        component: DiscountTypeExcel,
        name: "DiscountTypeExcel",
      },
      {
        path: "/FinancialCenter",
        component: FinancialCenter,
        name: "FinancialCenter",
      },
      { path: "/403", component: PermissionDenied },
      { path: "/loader", component: dynamicLoader, name: "loader" },
      {
        path: "/clientHistory/:id",
        component: clientHistory,
        name: "clientHistory",
      },
      { path: "/test", component: TestPage, name: "TestPage" },
      { path: "/", component: NewHome, name: "NewHome" },
      { path: "/dashboard", component: HomePage, name: "home" },
      {
        path: "/BookingProfit",
        component: BookingProfit,
        name: "BookingProfit",
      },
      {
        path: "/add/roomViewGlobal",
        component: addRoomView,
        name: "addRoomView",
      },
      {
        path: "/roomViewGlobal",
        component: roomViewGlobal,
        name: "roomViewGlobal",
      },
      {
        path: "/book-offline-service",
        component: bookOfflineService,
        name: "bookOfflineService",
      },
      {
        path: "/book-offline-service/:id",
        component: bookOfflineService,
        name: "bookOfflineServiceShow",
      },
      {
        path: "/book-offline-service/index",
        component: ServiceBookOfflineIndex,
        name: "ServiceBookOfflineIndex",
      },
      {
        path: "/edit/roomViewGlobal/:id",
        component: editroomViewGlobal,
        name: "editroomViewGlobal",
      },
      {
        path: "/incomeList",
        component: incomeList,
        name: "incomeList",
      },
      {
        path: "/flightsRevenue",
        component: FlightRevenue,
        name: "FlightRevenue",
      },
      {
        path: "/packageRevenue",
        component: PackageRevenue,
        name: "PackageRevenue",
      },
      {
        path: "/success_hotel_book",
        component: successHotelBook,
        name: "successHotelBook",
      },
      {
        path: "/permission/:id",
        component: permissiondetails,
        name: "permissiondetails",
      },
      { path: "/permission", component: permission, name: "permission" },
      {
        path: "/permissionrole",
        component: permissionrole,
        name: "permissionrole",
      },

      // B2c Users
      { path: "/b2cUsers", component: b2cUsers, name: "b2cUsers" },

      { path: "/books_Package", component: booksPackage, name: "booksPackage" },
      {
        path: "/b2cUsersflight",
        component: b2cUserFlight,
        name: "b2cUserFlight",
      },
      {
        path: "/pending_book/:type",
        component: bookPending,
        name: "bookPending",
      },
      {
        path: "/b2cUsersflight/:id",
        component: b2cUserFlightDetails,
        name: "b2cUserFlightDetails",
      },

      // import Umrapage
      { path: "/package/add/:type", component: bookUmrah, name: "bookUmrah" },
      // {
      //   path: "/AccountsBanks",
      //   component: AccountsBanks,
      //   name: "AccountsBanks",
      // },
      { path: "/package/:type/:id", component: editUmrah, name: "editUmrah" },
      {
        path: "/nationality_pacakge_price/:id",
        component: nationalityPacakgePrice,
        name: "nationalityPacakgePrice",
      },
      {
        path: "/nationality_pacakge_price/:id/edit/:nationality_id",
        component: nationalityPacakgePriceEdit,
        name: "nationalityPacakgePriceEdit",
      },
      {
        path: "/package_nationality/:id",
        component: packageNationality,
        name: "packageNationality",
      },
      {
        path: "/package/:packageType",
        component: umrahPackageTable,
        name: "umrahPackageTable",
      },
      { path: "/trip_programe", component: tripPrograme, name: "tripPrograme" },
      {
        path: "/umrah/book_details/:id",
        component: umrahBookDetails,
        name: "umrahBookDetails",
      },

      // organization routes
      {
        path: "/organization-form",
        component: FormOrganization,
        name: "FormOrganization",
      },
      {
        path: "/organization-tree",
        component: TreeOrganization,
        name: "TreeOrganization",
      },
      {
        path: "/organization-theme/:id",
        component: webTheme,
        name: "webTheme",
      },
      {
        path: "/organization-form/edit/:id",
        component: editOrganization,
        name: "editOrganization",
      },
      {
        path: "/organization-hotels/add",
        component: organizationHotelsAdd,
        name: "organizationHotelsAdd",
      },
      {
        path: "/organization-hotels",
        component: organizationHotels,
        name: "organizationHotels",
      },
      {
        path: "/organization-form/details/:id",
        component: detailsOrganization,
        name: "detailsOrganization",
      },
      {
        path: "/sub_organization/:id",
        component: subOrganization,
        name: "subOrganization",
      },
      {
        path: "/setOrganizationprovider",
        component: setOrganizationprovider,
        name: "setOrganizationprovider",
      },
      {
        path: "/news",
        component: newOrganization,
        name: "newOrganization",
      },
      // points
      // { path: "/points", component: showPoints, name: "showPoints" },
      // {
      //   path: "/coponsDetails",
      //   component: coponsDetails,
      //   name: "coponsDetails",
      // },
      // email setting

      { path: "/email-Setting", component: AddEmail, name: "AddEmail" },
      {
        path: "/portal-email-Setting",
        component: AddPortalEmail,
        name: "AddPortalEmail",
      },
      // employment routes
      { path: "/employees", component: AllEmpolyment, name: "AllEmpolyment" },
      {
        path: "/employee-logs/:id",
        component: employeeLogs,
        name: "employeeLogs",
      },
      {
        path: "/employees/add",
        component: AddEmpolyment,
        name: "AddEmpolyment",
      },
      {
        path: "/confirm-multiReservation",
        component: confirmReservation,
        name: "confirmReservation",
      },
      {
        path: "/confirm-reservation-unposted",
        component: pendingReservation,
        name: "pendingReservation",
      },
      {
        path: "/employees/edit/:id",
        component: EditEmpolyment,
        name: "EditEmpolyment",
      },
      // receipt routes
      { path: "/receipt", component: AllReceipt, name: "AllReceipt" },
      { path: "/receipt/add", component: AddReceipt, name: "AddReceipt" },
      {
        path: "/receipt/edit/:id",
        component: EditReceipt,
        name: "EditReceipt",
      },
      {
        path: "/receipt/copy/:id",
        component: CopiedReceipt,
        name: "CopiedReceipt",
      },
      // expenss routes
      { path: "/expenss", component: AllExpensess, name: "AllExpensess" },
      { path: "/expenss/add", component: AddExpensess, name: "AddExpensess" },
      {
        path: "/expenss/edit/:id",
        component: EditExpensess,
        name: "EditExpensess",
      },
      // revenue routes
      { path: "/revenue", component: AllRevenue, name: "AllRevenue" },
      { path: "/revenue/add", component: AddRevenue, name: "AddRevenue" },
      {
        path: "/revenue/edit/:id",
        component: EditRevenue,
        name: "EditRevenue",
      },
      {
        path: "/add/:type",
        component: addExchangeOrRecipet,
        name: "addExchangeOrRecipet",
      },
      {
        path: "/show/:type",
        component: showExchangeOrRecipet,
        name: "showExchangeOrRecipet",
      },
      // exhange routes
      { path: "/exchange", component: AllExchange, name: "AllExchange" },
      { path: "/exchange/add", component: AddExchange, name: "AddExchange" },
      {
        path: "/exchange/edit/:id",
        component: EditExchange,
        name: "EditExchange",
      },
      // provider routes
      { path: "/provider", component: AllProvider, name: "AllProvider" },
      {
        path: "/credintial/:id",
        component: AllCredential,
        name: "AllCredential",
      },
      { path: "/provider/add", component: AddProvider, name: "AddProvider" },
      {
        path: "/provider/edit/:id",
        component: EditProvider,
        name: "EditProvider",
      },
      {
        path: "/provider/add-cred/:id",
        component: AddProviderCredintil,
        name: "AddProviderCredintil",
      },
      // constructor routes
      {
        path: "/restrictions",
        component: AllConstructor,
        name: "AllConstructor",
      },

      {
        path: "/restrictions/add",
        component: AddConstructor,
        name: "AddConstructor",
      },
      {
        path: "/invoice-theme/show",
        component: InvoiceTheme,
        name: "Invoice-Theme",
      },
      {
        path: "/invoice-theme/add",
        component: AddInvoiceTheme,
        name: "Invoice-Theme-add",
      },
      {
        path: "/update-invoice-theme",
        component: EditInvoiceTheme,
        name: "Invoice-Theme-edit",
      },
      {
        path: "/room/book/:day/:status/:hotel_id",
        component: roomBooks,
        name: "roomBooks",
      },

      { path: "/paid", component: paidData, name: "paidData" },
      // contract routes
      { path: "/contract", component: Contract, name: "Contract" },
      {
        path: "/days-stock/:id",
        component: DayStock,
        name: "DayStock",
      },
      { path: "/contract/room", component: ContractRoom, name: "ContractRoom" },
      { path: "/contract/add", component: AddContract, name: "AddContract" },
      {
        path: "/contract/:id/edit",
        component: editContract,
        name: "editContract",
      },
      {
        path: "/contract/:contract_id/reservations",
        component: ContractReservations,
        name: "ContractReservations",
      },

      {
        path: "/contract/:id/seasons",
        component: showSeason,
        name: "showSeason",
      },
      {
        path: "/contract/:id/seasons/add",
        component: addSeason,
        name: "addSeason",
      },
      {
        path: "/contract/:id/seasons/edit/:season_id",
        component: editSeason,
        name: "editSeason",
      },
      {
        path: "/contract/:id/seasons/:season_id/edit-meal/:rate_id",
        component: editSeasonsMeals,
        name: "editSeasonsMeals",
      },
      {
        path: "/contract/:id/seasons/:season_id/rate-code",
        component: rateCodeContracrt,
        name: "rateCodeContracrt",
      },
      {
        path: "/contract/:id/seasons/:season_id/rate-code/:rate_id",
        component: editRateCode,
        name: "editRateCode",
      },
      {
        path: "/ratecode/history/:id",
        component: rateCodeContracrtHistory,
        name: "rateCodeContracrtHistory",
      },
      {
        path: "/days-stock/history/:id",
        component: DayStockHistory,
        name: "DayStockHistory",
      },
      // contract routes

      // safe routes
      { path: "/safe", component: AllSafes, name: "AllSafes" },
      { path: "/safe/add", component: AddSafes, name: "AddSafes" },
      { path: "/safe/edit/:id", component: EditSafes, name: "EditSafes" },
      {
        path: "/safe/details/:id",
        component: softDetails,
        name: "softDetails",
      },
      { path: "/allTransfers", component: AllTransfers, name: "AllTransfers" },

      {
        path: "/allTransfers/add",
        component: addTransfer,
        name: "addTransfer",
      },
      {
        path: "/allTransfers/show/:id",
        component: showTransfer,
        name: "showTransfer",
      },
      // account routes
      { path: "/account", component: bankAccount, name: "bankAccount" },
      {
        path: "/trial-balance",
        component: moneyListCenter,
        name: "moneyListCenter",
      },

      {
        path: "/trial-balance/:id",
        component: moneyListCenterChild,
        name: "moneyListCenterChild",
      },
      { path: "/account/add", component: AddAccount, name: "AddAccount" },
      {
        path: "/account/edit/:id",
        component: editAccount,
        name: "editAccount",
      },
      {
        path: "/account/details/:id",
        component: AccountDetails,
        name: "AccountDetails",
      },
      {
        path: "/account_statement",
        component: accountStatement,
        name: "accountStatement",
      },
      {
        path: "/general-account-statement",
        component: general_account_statement,
        name: "general_account_statement",
      },
      // treeBranches routes
      { path: "/tree", component: treeBranches, name: "treeBranches" },
      {
        path: "/tree/:type/:id/:cost_center_id",
        component: detialsBranch,
        name: "detialsBranch",
      },
      {
        path: "/tree_details/:branch_id/:costcenter",
        component: processCostCenter,
        name: "processCostCenter",
      },
      {
        path: "/opening-balance",
        component: openingBalance,
        name: "openingBalance",
      },
      {
        path: "/whatsapp-config",
        component: whatsappConfig,
        name: "whatsappConfig",
      },
      {
        path: "/email-config",
        component: emailConfig,
        name: "emailConfig",
      },

      { path: "/student-notes", component: studentNote, name: "studentNote" },

      // wallet routes
      { path: "/wallet", component: walletPage, name: "walletPage" },
      // room routes
      {
        path: "/room",
        component: allRooms,
        name: "allRooms",
      },
      {
        path: "/room/add",
        component: addRooms,
        name: "addRooms",
      },
      {
        path: "/room/edit/:roon_id",
        component: editRoom,
        name: "editRoom",
      },
      // view routes
      { path: "/view", component: allViews, name: "allViews" },
      { path: "/view/edit/:id", component: editView, name: "editView" },
      // bed routes
      { path: "/bed", component: allBeds, name: "allBeds" },
      { path: "/bed/edit/:id", component: editBed, name: "editBed" },
      // meal routes
      { path: "/meal", component: allMeals, name: "allMeals" },
      { path: "/meal/edit/:id", component: editMeal, name: "editMeal" },

      // facilities routes
      { path: "/facilities", component: allFacilities, name: "allFacilities" },
      {
        path: "/facilities/edit/:id",
        component: editFacilities,
        name: "editFacilities",
      },

      // countries routes
      { path: "/countries", component: AllCountries, name: "AllCountries" },
      { path: "/countries/add", component: addCountry, name: "addCountry" },
      {
        path: "/countries/edit/:id",
        component: editCountry,
        name: "editCountry",
      },

      // city routes
      { path: "/cities", component: allCities, name: "allCities" },
      { path: "/cities/add", component: addCities, name: "addCities" },
      { path: "/cities/edit/:id", component: editCities, name: "editCities" },

      // hotel routes
      { path: "/allHotels", component: allHotels, name: "allHotels" },

      // header text
      { path: "/header_text", component: HeaderText, name: "header-text" },
      // header text

      { path: "/allHotels/add", component: addHotel, name: "addHotel" },
      { path: "/allHotels/edit/:id", component: editHotel, name: "editHotel" },
      { path: "/revenues", component: revenues, name: "revenues" },

      // Suppliers routes
      { path: "/suppliers", component: showSuppliers, name: "showSuppliers" },
      {
        path: "/suppliers/add/:page",
        component: addSuppliers,
        name: "addSuppliers",
      },
      {
        path: "/suppliers/edit/:id",
        component: editSuppliers,
        name: "editSuppliers",
      },
      {
        path: "/suppliers/details/:id",
        component: detailsSuppliers,
        name: "detailsSuppliers",
      },

      // Suppliers routes
      { path: "/clients", component: showClients, name: "showClients" },
      { path: "/clients/add/:page", component: addClients, name: "addClients" },
      {
        path: "/clients/edit/:id",
        component: editClients,
        name: "editClients",
      },
      {
        path: "/clients/details/:id",
        component: clientsDetails,
        name: "clientsDetails",
      },
      {
        path: "/portal_user",
        component: porralUser,
        name: "porralUser",
      },

      // hotels routes
      { path: "/hotels/new", component: hotelsPageNew, name: "hotelsPageNew" },
      { path: "/hotels", component: hotelsPage, name: "hotelsPage" },
      {
        path: "/hotel/:id",
        component: hotelsDetailsPage,
        name: "hotelsDetailsPage",
      },
      {
        path: "/rooming_list",
        component: roomingListPage,
        name: "roomingListPage",
      },
      { path: "/arrival", component: arrivalPage, name: "arrivalPage" },

      // booking routes
      { path: "/new_book", component: newBookPage, name: "newBookPage" },
      {
        path: "/new_collection_book",
        component: newCollectionBookPage,
        name: "newCollectionBookPage",
      },
      { path: "/book", component: bookPage, name: "bookPage" },

      // books

      // { path: "/books", component: bookShow, name: "bookShow" },
      // { path: "/sale", component: salePone, name: "salePone" },

      // Motion detection

      {
        path: "/movement-detection/:type",
        component: MovementDetection,
        name: "MovementDetection",
      },

      // book offline routes
      {
        path: "/offlineBookHotels",
        component: offlineBookHotelsIndex,
        name: "offlineBookHotels",
      },
      {
        path: "/BookHotels-portal",
        component: portalBookHotelsIndex,
        name: "BookHotelsPortal",
      },
      {
        path: "/evidence-limitations",
        component: evidenceLimitations,
        name: "evidenceLimitations",
      },
      {
        path: "/hotels/book_offline",
        component: hotelsBookOffline,
        name: "hotelsBookOffline",
      },
      {
        path: "/hotels/book_offline/:id",
        component: updateHotelsBookOffline,
        name: "updateHotelsBookOffline",
      },
      {
        path: "/hotels/book_offline/:id/history",
        component: HotelsBookOfflineHistory,
        name: "HotelsBookOfflineHistory",
      },
      {
        path: "/paid-supplier",
        component: paidSupplier,
        name: "paidSupplier",
      },
      {
        path: "/hotels/book_offline/:id/payment/:book_id/:status",
        component: hotelsPaymentMethds,
        name: "hotelsPaymentMethds",
      },
      {
        path: "/hotels/book_offline/:id/:serial_book/attachments",
        component: hotelsAttachmentTable,
        name: "hotelsAttachmentTable",
      },
      {
        path: "/hotels/book_offline/:id/attachments/add",
        component: hotelsAttachmentAdd,
        name: "hotelsAttachmentAdd",
      },
      {
        path: "/hotels/book_offline/:id/attachments/:attach_id",
        component: hotelsAttachmentEdit,
        name: "hotelsAttachmentEdit",
      },
      {
        path: "/purchases-bill",
        component: purchasesBill,
        name: "purchasesBill",
      },
      {
        path: "/purchases-bill/add",
        component: purchasesBillAdd,
        name: "purchasesBillAdd",
      },
      {
        path: "/purchases-bill/show/:id",
        component: purchasesBillShow,
        name: "purchasesBillShow",
      },
      {
        path: "/book_offline",
        component: bookOfflinePage,
        name: "bookOfflinePage",
      },
      {
        path: "/book_table_type/:bookType",
        component: bookTablePage,
        name: "bookTablePage",
      },
      {
        path: "/post_to_account",
        component: PostToAccount,
        name: "PostToAccount",
      },
      {
        path: "/book_table_type",
        component: allBookTablePage,
        name: "allBookTablePage",
      },
      {
        path: "/history/:id",
        component: historyBookPage,
        name: "historyBookPage",
      },
      {
        path: "/book_table/edit/:id",
        component: editBookPage,
        name: "editBookPage",
      },
      {
        path: "/book_table/:id",
        component: showBookPage,
        name: "showBookPage",
      },
      {
        path: "/book_table/:id/paid",
        component: paidHotels,
        name: "paidHotels",
      },
      {
        path: "/book_table/:id/:type",
        component: invoiceOffline,
        name: "invoiceOffline",
      },
      {
        path: "/invoice/:id/:type",
        component: newInvoiceOffline,
        name: "newInvoiceOffline",
      },

      {
        path: "/tax-invoice-ar/:id",
        component: newTaxInvoiceArOffline,
        name: "newTaxInvoiceArOffline",
      },

      {
        path: "/tax-invoice-en/:id",
        component: newTaxInvoiceEnOffline,
        name: "newTaxInvoiceEnOffline",
      },

      {
        path: "/book_table/:id/paid/add",
        component: addPaidHotels,
        name: "addPaidHotels",
      },
      // bookFlight routes
      { path: "/bookFlight", component: bookFlight, name: "bookFlight" },
      {
        path: "/successFlightbook",
        component: successFlightbook,
        name: "successFlightbook",
      },
      // flight routes
      { path: "/flight", component: flightPage, name: "flightPage" },
      {
        path: "/flight-detials",
        component: flightDetials,
        name: "flightDetials",
      },
      { path: "/hotel-view/:id", component: hotelView, name: "hotelView" },
      { path: "/pills/:id", component: pillsHotel, name: "pillsHotel" },
      { path: "/report_flight", component: ReportFlight, name: "ReportFlight" },
      {
        path: "/rooming-list-report",
        component: roomimgListReport,
        name: "roomingListReport",
      },
      // penndinMarkters
      {
        path: "/penndingMarkters",
        component: penndinMarkters,
        name: "penndinMarkters",
      },
      {
        path: "/penddingGifts",
        component: penddingPoints,
        name: "penddingPoints",
      },
      // penndinMarkters

      //flight contract
      {
        path: "/flight-contract",
        component: flightContractIndex,
        name: "flightContractIndex",
      },

      {
        path: "/flight-contract/add",
        component: flightContractAdd,
        name: "flightContractAdd",
      },
      {
        path: "/flight-contract/edit/:id",
        component: flightContractEdit,
        name: "flightContractEdit",
      },

      //flight contract
      {
        path: "/flight-contract/:id/flight-season",
        component: flightSeasonsIndex,
        name: "flightSeasonsIndex",
      },
      {
        path: "/flight-contract/:id/flight-season/add",
        component: flightSeasonsAdd,
        name: "flightSeasonsAdd",
      },
      {
        path: "/flight-contract/:id/flight-season/edit/:seasonId",
        component: flightSeasonsEdit,
        name: "flightSeasonsEdit",
      },

      // flight rateCode
      {
        path: "/flight-contract/:id/flight-season/:seasonId/rateCodes",
        component: flightRateCodesIndex,
        name: "flightRateCodesIndex",
      },
      {
        path: "/flight-contract/:id/flight-season/:seasonId/rateCodes/add",
        component: flightRateCodesAdd,
        name: "flightRateCodesAdd",
      },

      // flight offlin routes
      {
        path: "/flight-offline",
        component: flightOffline,
        name: "flightOffline",
      },
      {
        path: "/flight-booking",
        component: flightBooking,
        name: "flightBooking",
      },
      {
        path: "/flight-booking/:id",
        component: editFlightBooking,
        name: "editFlightBooking",
      },
      {
        path: "/flights/flight-pdf/:id",
        component: flightPdf,
        name: "flightPdf",
      },

      // Points
      { path: "/AddPoints", component: AddPoints, name: "AddPoints" },
      { path: "/EditPoints/:id", component: EditPoints, name: "EditPoints" },
      // Points

      // Gift
      { path: "/gift", component: Gifts, name: "GiftsPage" },
      {
        path: "/tax-reports/index",
        component: salesReports,
        name: "salesReportsIndex",
      },
      {
        path: "/tax-reports-due",
        component: TaxReportsDue,
        name: "TaxReportsDue",
      },
      {
        path: "/purchase-reports/index",
        component: purchaseReports,
        name: "purchaseReportsIndex",
      },
      { path: "/gift/add", component: AddGifts, name: "AddGifts" },
      { path: "/gift/edit/:id", component: EditGifts, name: "EditGifts" },
      // Gift

      // airplane routes
      { path: "/airport", component: airportIndex, name: "airportIndex" },
      { path: "/airport/add", component: airportAdd, name: "airportAdd" },
      {
        path: "/airport/edit/:id",
        component: airportEdit,
        name: "airportEdit",
      },

      // flight company routes
      {
        path: "/flight-company",
        component: flightCompanyIndex,
        name: "flightCompanyIndex",
      },
      {
        path: "/flight-company/add",
        component: flightCompanyAdd,
        name: "flightCompanyAdd",
      },
      {
        path: "/flight-company/edit/:id",
        component: flightCompanyEdit,
        name: "flightCompanyEdit",
      },

      // reservtion routes
      {
        path: "/reservation/detials",
        component: reservationDetails,
        name: "reservationDetails",
      },
      {
        path: "/reservation/show",
        component: reservationShow,
        name: "reservationShow",
      },
      {
        path: "/during-period/:type",
        component: duringPeriod,
        name: "duringPeriod",
      },

      // airplane routes
      {
        path: "/nationality",
        component: nationalityIndex,
        name: "nationalityIndex",
      },
      {
        path: "/nationality/add",
        component: nationalityAdd,
        name: "nationalityAdd",
      },
      {
        path: "/nationality/edit/:nation_id",
        component: nationalityEdit,
        name: "nationalityEdit",
      },

      {
        path: "/services_dashboard",
        component: servicesDashboard,
        name: "servicesDashboard",
      },
      {
        path: "/services_dashboard/add",
        component: servicesDashboardAdd,
        name: "servicesDashboardAdd",
      },
      {
        path: "/services_dashboard/edit/:id",
        component: servicesDashboardEdit,
        name: "servicesDashboardEdit",
      },

      { path: "/payment", component: paymentIndex, name: "paymentIndex" },
      { path: "/payment-add", component: paymentAdd, name: "paymentAdd" },

      //invoicses
      {
        path: "/invoices/AMENDMENT",
        component: invoicesFirst,
        name: "invoicesFirst",
      },

      // flights Routes
      {
        path: "/flights-companies",
        component: flightCompanies,
        name: "flightCompanies",
      },
      {
        path: "/all-flight-companies",
        component: flightCompaniesIndex,
        name: "flightCompaniesIndex",
      },
      {
        path: "/flights-companies/edit/:id",
        component: flightCompaniesEdit,
        name: "flightCompaniesEdit",
      },
      {
        path: "/flights-companies/routes/:id",
        component: flightCompaniesRoutes,
        name: "flightCompaniesRoutes",
      },

      //transporter
      { path: "/car_type/add", component: carTypeAdd, name: "carTypeAdd" },
      { path: "/car_type", component: carType, name: "carType" },
      {
        path: "/car_type/edit/:id",
        component: carTypeEdit,
        name: "carTypeEdit",
      },
      { path: "/car_cat/add", component: carCatAdd, name: "carCatAdd" },
      { path: "/car_cat", component: carCat, name: "carCat" },
      { path: "/car_cat/edit/:id", component: carCatEdit, name: "carCatEdit" },

      {
        path: "/contract-transport/add",
        component: contractTransportAdd,
        name: "contractTransportAdd",
      },
      {
        path: "/contract-transport",
        component: contractTransportIndex,
        name: "contractTransportIndex",
      },
      {
        path: "/contract-transport/edit/:id",
        component: contractTransportEdit,
        name: "contractTransportEdit",
      },

      {
        path: "/contract-transport/:id/season-transport/add",
        component: seasonTransportAdd,
        name: "seasonTransportAdd",
      },
      {
        path: "/contract-transport/:id/season-transport/edit/:seasonId",
        component: seasonTransportEdit,
        name: "seasonTransportEdit",
      },
      {
        path: "/contract-transport/:id/season-transport",
        component: seasonTransportIndex,
        name: "seasonTransportIndex",
      },
      {
        path: "/contract-transport/:id/season-transport/:seasonId/rate-code",
        component: rateCodeTransport,
        name: "rateCodeTransport",
      },
      {
        path: "/contract-transport/:id/season-transport/:seasonId/rate-code/add",
        component: AddrateCodeTransport,
        name: "AddrateCodeTransport",
      },
      {
        path: "/contract-transport/:id/season-transport/:seasonId/rate-code/:rateId",
        component: editrateCodeTransport,
        name: "editrateCodeTransport",
      },

      // import visa
      { path: "/visa", component: allVisa, name: "allVisa" },
      { path: "/visa/add", component: addVisa, name: "addVisa" },
      { path: "/visa/edit/:id", component: editVisa, name: "editVisa" },
      {
        path: "/visa/contracts",
        component: allContracts,
        name: "allContracts",
      },
      {
        path: "/visa/add_contract",
        component: addVisaContract,
        name: "addVisaContract",
      },
      {
        path: "/visa/contract/edit/:id",
        component: editVisaContract,
        name: "editVisaContract",
      },
      {
        path: "/visa/contract/:id/seasons",
        component: allVisaSeasons,
        name: "allVisaSeasons",
      },
      {
        path: "/visa/contract/:id/seasons/add",
        component: addVisaSeason,
        name: "addVisaSeason",
      },
      {
        path: "/visa/contract/:id/seasons/edit/:season_id",
        component: editVisaSeason,
        name: "editVisaSeason",
      },
      {
        path: "/visa/contract/:id/seasons/:season_id/rate-code",
        component: addRateCode,
        name: "addRateCode",
      },
      {
        path: "/visa/contract/:id/seasons/:season_id/rate-code/:rate_id",
        component: editVisaRateCode,
        name: "editVisaRateCode",
      },
      {
        path: "/visa/contract/:id/seasons/:season_id/allRateCode",
        component: allRateCode,
        name: "allRateCode",
      },
      // packageStarCategory
      {
        path: "/package_Star_Category_Add",
        component: pagckageStarCategoryAdd,
        name: "packageStarCategoryAdd",
      },
      {
        path: "/package_Star_Category",
        component: packageStarCategory,
        name: "packageStarCategory",
      },
      {
        path: "/package_Star_Category_Edit/:id",
        component: pagckageStarCategoryEdit,
        name: "packageStarCategoryEdit",
      },

      // packageStarCategory

      // stage-setting
      {
        path: "/stage_percentages/add",
        component: stageSetting,
        name: "stageSetting",
      },
      { path: "/stage_percentages", component: stageShow, name: "stageShow" },

      { path: "/Affiliates", component: Affiliates, name: "Affiliates" },

      {
        path: "/affiliates/add",
        component: affiliatesAdd,
        name: "affiliatesAdd",
      },

      {
        path: "/affiliates/edit/:id",
        component: affiliatesEdit,
        name: "affiliatesEdit",
      },

      { path: "/bookCount", component: bookCount, name: "bookCount" },

      { path: "/markters", component: markters, name: "markters-comp" },

      { path: "/markters/Add", component: marktersAdd, name: "marktersAdd" },

      {
        path: "/markters/Edit/:id",
        component: marktersEdit,
        name: "marktersEdit",
      },
    ],
  },
  {
    path: "/website",
    component: Website,
    meta: { requiresAuth: true },
    name: "Website",
    // beforeEnter: (to, from, next) => {
    //   // Check if the user is authenticated based on data in localStorage
    //   const userData = JSON.parse(localStorage.getItem("user"));
    //   const userEmail = userData ? userData.email_company : null;

    //   // Specify the email you want to check against
    //   const targetEmail = "info@tripsavisor.com";

    //   if (userEmail === targetEmail) {
    //     // User's email matches the target email, allow access to the dashboard
    //     next();
    //   } else {
    //     // User's email does not match the target email, redirect to the home page
    //     next("/dashboard");
    //   }
    // },
    children: [
      //import about
      { path: "/header_about", component: aboutHeader, name: "aboutHeader" },

      //currency
      {
        path: "/defaultCurrency",
        component: defaultCurrency,
        name: "defaultCurrency",
      },
      {
        path: "/currency/add",
        component: addCurrency,
        name: "addCurreny",
      },
      {
        path: "/currency/edit/:id",
        component: editCurrency,
        name: "currencyEdit",
      },
      {
        path: "/currencies",
        component: currencyIndex,
        name: "currencyIndex",
      },
      {
        path: "/about/:type",
        component: aboutSecondIndex,
        name: "aboutSecondIndex ",
      },
      {
        path: "/about/:type/add",
        component: aboutSecondAdd,
        name: "aboutSecondAdd",
      },
      {
        path: "/about/:type/edit/:id",
        component: aboutSecondEdit,
        name: "aboutSecondEdit",
      },

      { path: "/umrah", component: umrahPackage, name: "umrahPackage" },
      {
        path: "/Insurance_Companies_Add",
        component: InsuranceCompaniesAdd,
        name: "InsuranceCompaniesAdd",
      },
      {
        path: "/suplierTypeAdd",
        component: suplierTypeAdd,
        name: "suplierTypeAdd",
      },
      {
        path: "/suplierTypeEdit/:id",
        component: suplierTypeEdit,
        name: "suplierTypeEdit",
      },
      {
        path: "/suplierType",
        component: suplierType,
        name: "suplierType",
      },
      {
        path: "/clientTypeAdd",
        component: clientTypeAdd,
        name: "clientTypeAdd",
      },
      {
        path: "/client-type-portal/add",
        component: clientTypePortalAdd,
        name: "clientTypePortalAdd",
      },
      {
        path: "/client-type-portal/edit/:id",
        component: clientTypePortalEdit,
        name: "clientTypePortalEdit",
      },
      {
        path: "/client-type-portal",
        component: clientTypePortal,
        name: "clientTypePortal",
      },
      {
        path: "/clientTypeEdit/:id",
        component: clientTypeEdit,
        name: "clientTypeEdit",
      },
      {
        path: "/clientType",
        component: clientType,
        name: "clientType",
      },
      {
        path: "/Insurance_Companies_Edit/:id",
        component: InsuranceCompaniesEdit,
        name: "InsuranceCompaniesEdit",
      },
      {
        path: "/DiscountType_Edit/:id",
        component: DiscountTypeEdit,
        name: "DiscountTypeEdit",
      },
      {
        path: "/DiscountType",
        component: DiscountType,
        name: "DiscountType",
      },
      {
        path: "/DiscountTypeAdd",
        component: DiscountTypeAdd,
        name: "DiscountTypeAdd",
      },
      {
        path: "/Insurance_Companies",
        component: InsuranceCompanies,
        name: "InsuranceCompanies",
      },
      {
        path: "/Insurance_Type",
        component: InsuranceType,
        name: "InsuranceType",
      },
      {
        path: "/Insurance_Type_Add",
        component: InsuranceTypeAdd,
        name: "InsuranceTypeAdd",
      },
      {
        path: "/Insurance_Type_Edit/:id",
        component: InsuranceTypeEdit,
        name: "InsuranceTypeEdit",
      },

      //import client adentage
      {
        path: "/client-adventage",
        component: clientAdentage,
        name: "clientAdentage",
      },
      {
        path: "/client-adventage/add",
        component: clientAdentageAdd,
        name: "clientAdentageAdd ",
      },
      {
        path: "/client-adventage/edit/:id",
        component: clientAdentageEdit,
        name: "clientAdentageEdit",
      },

      { path: "/routers/add", component: routersAdd, name: "routersAdd" },
      {
        path: "/routers/edit/:id",
        component: routersEdit,
        name: "routersEdit",
      },
      { path: "/routers", component: routersIndex, name: "routersIndex" },

      {
        path: "/services_website",
        component: servicesWebsite,
        name: "servicesWebsite",
      },
      {
        path: "/services_website/add",
        component: servicesWebsiteAdd,
        name: "servicesWebsiteAdd",
      },
      {
        path: "/services_website/edit/:id",
        component: servicesWebsiteEdit,
        name: "servicesWebsiteEdit",
      },

      { path: "/visit_website", component: visitWebsite, name: "visitWebsite" },
      {
        path: "/visit_website/add",
        component: visitWebsiteAdd,
        name: "visitWebsiteAdd",
      },
      {
        path: "/visit_website/edit/:id",
        component: visitWebsiteEdit,
        name: "visitWebsiteEdit",
      },

      { path: "/hastag-blogs", name: "hastag-blogs", component: hastagBlogs },
      {
        path: "/add/hastag-blogs",
        name: "add-hastag-blogs",
        component: addHashtagBlogs,
      },
      {
        path: "/edit/hastag-blogs/:id",
        name: "edit-hastag-blogs",
        component: editHashtagBlogs,
      },
      {
        path: "/categorey-blogs",
        name: "categorey-blogs",
        component: categoreyBlogs,
      },
      {
        path: "/add/categorey-blogs",
        name: "add-categorey-blogs",
        component: addcategoryBlogs,
      },
      {
        path: "/edit/categorey-blogs/:id",
        name: "edit-categorey-blogs",
        component: editcategoryBlogs,
      },

      { path: "/blogs/:type", component: blogsWebsite, name: "blogsWebsite" },
      {
        path: "/blogs/:type/add",
        component: blogsWebsiteAdd,
        name: "blogsWebsiteAdd",
      },
      {
        path: "/blogs/:type/edit/:id",
        component: blogsWebsiteEdit,
        name: "blogsWebsiteEdit",
      },

      {
        path: "/client_opinion",
        component: clientOpinion,
        name: "clientOpinion",
      },
      {
        path: "/client_opinion/add",
        component: clientOpinionAdd,
        name: "clientOpinionAdd",
      },
      {
        path: "/client_opinion/edit/:id",
        component: clientOpinionEdit,
        name: "clientOpinionEdit",
      },

      {
        path: "/add_section",
        component: addSection,
        name: "addSection",
      },
      {
        path: "/app_section",
        component: appSection,
        name: "appSection",
      },
      {
        path: "/edit_section/:id",
        component: editSection,
        name: "editSection",
      },
      {
        path: "/sections",
        component: indexSection,
        name: "indexSection",
      },
      {
        path: "/package_sections",
        component: packageSections,
        name: "packageSections",
      },
      {
        path: "/package_sections/add",
        component: AddpackageSections,
        name: "AddpackageSections",
      },
      {
        path: "/package_sections/edit/:id",
        component: EditpackageSections,
        name: "EditpackageSections",
      },

      // { path: "/web_features", component: webFeatures, name: "webFeatures" },
      {
        path: "/web_features/add",
        component: webFeaturesAdd,
        name: "webFeaturesAdd",
      },
      // {
      //   path: "/web_features/edit",
      //   component: webFeaturesEdit,
      //   name: "webFeaturesEdit",
      // },

      { path: "/web_statuses", component: webStatuses, name: "webStatuses" },
      {
        path: "/web_statuses/add",
        component: webStatusesAdd,
        name: "webStatusesAdd",
      },
      {
        path: "/web_statuses/edit/:id",
        component: webStatusesEdit,
        name: "webStatusesEdit",
      },

      {
        path: "/website/setting",
        component: WebsiteSetting,
        name: "WebsiteSetting",
      },
      {
        path: "/website/header_images",
        component: WebsiteHeaderImages,
        name: "WebsiteHeaderImages",
      },
      { path: "/create-page", component: CreatePage, name: "CreatePage" },

      {
        path: "/contact_us/:type",
        component: contactWebsite,
        name: "contactWebsite",
      },
      { path: "/privacy/:type", component: policyWebsite, name: "policyWebsite" },
      { path: "/terms/:type", component: termsWebsite, name: "termsWebsite" },

      { path: "/over_price", component: overPrice, name: "overPrice" },
      { path: "/payment_fees", component: paymentFees, name: "paymentFees" },
      //  coupons
      { path: "/coupons/add", component: addCoupon, name: "addCoupon" },
      { path: "/coupons/edit/:id", component: editCoupon, name: "editCoupon" },
      { path: "/coupons", component: couponsShow, name: "coupons-Show" },

      // faq
      { path: "/faqs", component: faqsIndex, name: "faqsIndex" },
      { path: "/faqs/add", component: faqsAdd, name: "faqsAdd" },
      { path: "/faqs/edit/:id", component: faqsEdit, name: "faqsEdit" },

      //  hotel revenues
      {
        path: "/hotelRevenues",
        component: hotelRevenues,
        name: "hotelRevenues",
      },
      { path: "/paymentVisis", component: paymentVisis, name: "paymentVisis" },
      // package type
      { path: "/addPackage", component: addPackage, name: "addPackage" },
      {
        path: "/allPackageTypes",
        component: allPackageTypes,
        name: "allPackageTypes",
      },
      {
        path: "/packageType/edit/:id",
        component: editPackageType,
        name: "editPackageType",
      },
      {
        path: "/arrival_guests/b2b",
        component: arrivalGuestsB2b,
        name: "arrivalGuestsB2b",
      },
      {
        path: "/arrival_guests/b2c",
        component: arrivalGuestsB2c,
        name: "arrivalGuestsB2c",
      },
      {
        path: "/room_guests/b2b",
        component: roomGuestsB2b,
        name: "roomGuestsB2b",
      },
      {
        path: "/room_guests/b2c",
        component: roomGuestsB2c,
        name: "roomGuestsB2c",
      },
      // hotel offline
      {
        path: "/hotel/details/:id",
        component: offlineHotelDetails,
        name: "offlineHotelDetails",
      },
      { path: "/report_hotel", component: reportHotel, name: "reportHotel" },
      {
        path: "/hotel/addOfflineHotel",
        component: addOfflinehotel,
        name: "addOfflinehotel",
      },
      {
        path: "/hotel/editOfflineHotel/:id",
        component: editOfflinehotel,
        name: "editOfflinehotel",
      },
      // attachments type
      {
        path: "/hotel/add_attachment_type",
        component: addAttachmentType,
        name: "addAttachmentType",
      },
      {
        path: "/hotel/edit_attachment_type/:id",
        component: editAttachmentType,
        name: "EditAttachmentType",
      },
      {
        path: "/hotel/all_attachment_type",
        component: allAttachmentType,
        name: "allAttachmentType",
      },
      //  attachments
      {
        path: "/hotel/add_hotel_attachment",
        component: addHotelAttachment,
        name: "addHotelAttachment",
      },
      {
        path: "/hotel/edit_hotel_attachment/:id",
        component: editHotelAttachment,
        name: "editHotelAttachment",
      },
      {
        path: "/hotel/all_hotel_attachment",
        component: allHotelAttachment,
        name: "allHotelAttachment",
      },
      // popUp msg
      {
        path: "/PopUp/add",
        component: addPopUpMsg,
        name: "addPopUpMsg",
      },
      {
        path: "/PopUp/add/display",
        component: PopUpDisplay,
        name: "PopUpDisplay",
      },
      // addCostCenter
      {
        path: "/cost_center/add",
        component: addCostCenter,
        name: "addCostCenter",
      },
      {
        path: "/cost_center/edit/:id",
        component: editCostCenter,
        name: "editCostCenter",
      },
      {
        path: "/cost_center",
        component: costCenterIndex,
        name: "costCenterIndex",
      },
      {
        path: "/meta/add",
        component: addMeta,
        name: "addMeta",
      },
      {
        path: "/meta/edit/:id",
        component: editMeta,
        name: "editMeta",
      },
      {
        path: "/all_meta",
        component: metaIndex,
        name: "metaIndex",
      },
      {
        path: "/banner/:type",
        component: addBanner,
        name: "addBanner",
      },
      {
        path: "/banner/:type/:id",
        component: editBanner,
        name: "editBanner",
      },
      {
        path: "/banners/:type",
        component: allBanners,
        name: "allBanners",
      },
      {
        path: "/terms_and_conditions/add",
        component: addTerms,
        name: "addTerms",
      },
      {
        path: "/terms_and_conditions/edit/:id",
        component: editTerms,
        name: "editTerms",
      },
      {
        path: "/terms_and_conditions",
        component: termsIndex,
        name: "termsIndex",
      },
      {
        path: "/salesAnalysis/:type",
        component: salesAnalysis,
        name: "salesAnalysis",
      },
      {
        path: "/salesAnalysis/:type/:checkin/:checkout",
        component: salesAnalysisWithDates,
        name: "salesAnalysisWithDates",
      },
    ],
  },
  {
    path: "/welcome",
    component: Setup,
    meta: { requiresAuth: true },
    name: "setup",
    children: [
      { path: "/welcome", component: WelcomePage, name: "welcome" },
      { path: "/setting", component: SettingPage, name: "setting" },
      {
        path: "/setup_fees/:type_id",
        component: feesSetup,
        name: "setup_fees",
      },
      {
        path: "/custom_setup_fees/edit/:id",
        component: editCustomSetupFees,
        name: "edit_custom_setup_fees",
      },
    ],
  },

  // { path: "/404", component: NotFound},
  // { path: "/:catchAll(.*)", redirect: "/404"},
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  hashbang: false,
  mode: "html5",
  linkActiveClass: "active",
  // linkExactActiveClass: "exact-active",
  scrollBehavior() {
    // Scroll to the top of the page
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      let apps = JSON.parse(localStorage.getItem("user")).apps;
      if (apps.includes("System")) {
        console.log(apps, "app_name: System");
        // Handle System app permissions here
        // next();
      } else if (apps.includes("Website")) {
        console.log(apps, "app_name: Website");
        // Handle Website app permissions here
        // next();
      } else if (apps.includes("App")) {
        console.log(apps, "app_name: App");
        // Handle other app permissions here
        // next();
      }

      let status = JSON.parse(localStorage.getItem("user")).status;
      let is_portal_contract = JSON.parse(
        localStorage.getItem("user")
      ).is_portal_contract;

      if (status == 0) {
        next("/welcome");
      } else if (status == 1 && is_portal_contract === 1) {
        next("/contract/add");
      } else {
        next("/");
      }
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
