<template>
  <Loading v-if="Loading" class="loading w-100" />
  <section class="all_emp card_style">
    <div class="row options mb-2">
      <div class="col-6">
        <div class="main_header_style">
          <h4>{{ $t("The Hotels") }}</h4>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <router-link
          :to="{ name: 'addOfflinehotel' }"
          class="PrimaryButton btn w-50"
          ><i class="fa-solid fa-plus"></i> {{ $t("New Hotel") }}</router-link
        >
      </div>
    </div>
    <form class="row mb-3">
      <div class="form-group col-12 col-md-3">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          class="form-control"
          type="text"
          :placeholder="$t('search by name')"
          v-model="titleHotel"
          @input="debouncedSearchHotels"
        />
      </div>
      <div class="form-group col-12 col-md-3">
        <Multiselect
          v-model="country_id"
          :options="countries"
          label="title"
          track-by="id"
          :clear-on-select="true"
          :placeholder="$t('choose_country')"
          select-label=""
          :hide-selected="true"
          @update:model-value="FetchCityFromCountry"
        >
        </Multiselect>
      </div>
      <div class="form-group col-12 col-md-3">
        <div class="form-group">
          <Multiselect
            v-model="cityValue"
            :options="cities"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('enter_city')"
            select-label=""
            :hide-selected="true"
            @update:model-value="fetchHotel()"
          >
          </Multiselect>
        </div>
      </div>
      <div class="form-group col-12 col-md-3">
        <Multiselect
          v-model="this.hotels.amenities"
          :options="countries"
          label="title"
          track-by="id"
          multiple
          :clear-on-select="true"
          :placeholder="$t('choose_country')"
          select-label=""
          :hide-selected="true"
          @update:model-value="FetchCityFromCountry"
        >
        </Multiselect>
      </div>
    </form>

    <div class="row">
      <table class="table" id="hotelTable">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("Title") }}</th>
            <th>{{ $t("country") }}</th>
            <th>{{ $t("city") }}</th>
            <th>{{ $t("rates") }}</th>
            <th>{{ $t("Image") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(hotel, index) in hotels.data" :key="index">
            <td>{{ hotel.id }}</td>
            <td>{{ hotel.title }}</td>
            <td>{{ hotel.country != null ? hotel.country.title : "" }}</td>
            <td>{{ hotel.city != null ? hotel.city.title : "" }}</td>
            <td>{{ hotel.starCount }}</td>
            <td><img :src="hotel.image" alt="" /></td>
            <td>
              <div
                class="btn btn-warning"
                :title="$t('call_rooms')"
                @click="callRooms(hotel.id)"
              >
                <i class="fa-solid fa-hotel"></i>
              </div>

              <router-link
                :to="`/hotel/editOfflineHotel/${hotel.id}`"
                class="btn btn-success mx-1 text-white"
                :title="$t('Edit')"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </router-link>

              <router-link
                :to="`/hotel/details/${hotel.id}`"
                class="btn btn-info"
                :title="$t('hotel details')"
              >
                <i class="fa-solid fa-circle-exclamation"></i>
              </router-link>

              <div
                class="btn btn-danger text-white mx-1"
                @click="deleteHotel(hotel.id, index)"
                :title="$t('Delete')"
              >
                <i class="fa-solid fa-trash"></i>
              </div>
              <button
                @click="toggleFavorite(hotel.id)"
                :class="`btn ${
                  hotel.is_favorite == 1 ? ' btn-warning' : 'btn-secondary'
                }`"
              >
                <i class="fa-solid fa-star text-white"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="hotels"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchHotel"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import pagination from "laravel-vue-pagination";
import Loading from "@/components/LottieFiles/loading.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "allHotels",
  data() {
    return {
      hotels: [],
      titleHotel: "",
      Loading: false,
      countries: [],
      country_id: "",
      cityValue: "",
      cities: [],
      debounceTimeout: null,
      isRequestPending: false,
      hotelSearchLoading: false,
    };
  },
  methods: {
    toggleFavorite(id) {
      this.Loading = true;
      axios.post("/change_hotel_favorite_status", { hotel_id: id }).then(() => {
        this.fetchHotel();
        Swal.fire({
          title: "",
          text: this.$t("change_favorite"),
          icon: "success",
        });
        this.Loading = false;
      });
    },
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    // fetch city
    FetchCityFromCountry(value) {
      let basic_country_ids = {
        country_id: [value.id],
      };
      // console.log(this.country_id);
      // console.log(basic_country_ids);
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
          // console.log(this.cities);
        });
    },

    // Debounce function with character length check
    debouncedSearchHotels(event) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        if (event.target.value.length <= 2) {
          return; // Exit if the input length is 2 or less
        }
        this.fetchHotel(event);
      }, 1000); // Adjust the delay as needed (e.g., 1000ms)
    },

    fetchHotel(page = 1) {
      if (this.isRequestPending) return;
      this.hotelSearchLoading = true;

      this.isRequestPending = true;

      const formData = new FormData();
      formData.append("word", this.titleHotel);
      if(this.cityValue) {
        formData.append("city_id", this.cityValue.id);
      }
      axios
        .post(`/fetch_custom_hotels?page=${page}`, formData)
        .then(({ data }) => {
          this.hotels = data.data;
        }).catch((err) => console.log(err))
        .finally(() => {
          this.isRequestPending = false;
          this.hotelSearchLoading = false;
        });
    },
    deleteHotel(hotelId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios
            .delete(`/offline_hotels/${hotelId}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire({
                  title: "",
                  text: data.message,
                  icon: "success",
                });
                this.hotels.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire({
                  title: "",
                  text: data.message,
                  icon: "error",
                });
              }
            })
            .catch((error) => {
              if (error.response.status != 403) {
                Swal.fire({
                  title: "",
                  text: error.response.data.message,
                  icon: "error",
                });
              }
            });
        }
      });
    },

    callRooms(hotelId) {
      let id = { hotel_id: hotelId };
      axios
        .post(`/save_hotel_rooms`, id)
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "error",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetchHotel();
    this.fetch_country();
  },
  components: {
    pagination,
    Loading,
    Multiselect,
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100px;
  height: 100px;
}
.form-group {
  position: relative;
  margin-bottom: unset !important;
  i {
    position: absolute;
    right: 15px;
  }
}
</style>
