<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("edit_company_flight") }}</h4>
    </div>
    <p v-for="(name, index) in program_name" :key="index">
      {{ name.locale }}: {{ name.name }}
      <span
        ><i
          @click="deleteName(index)"
          class="fa-solid fa-trash-can text-danger mx-2 cursor-pointer"
        ></i
      ></span>
    </p>
    <form @submit.prevent="editCompany()">
      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The Country") }}</label>
            <Multiselect
              v-model="countryValue"
              track-by="id"
              label="title"
              :options="countries"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="adress">{{ $t("code") }}</label>
            <input
              type="text"
              class="form-control"
              id="code"
              :placeholder="$t('code')"
              v-model="code"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("write_name") }}</label>
            <TabView>
              <TabPanel
                v-for="(program, index) in program_name"
                :key="index"
                :class="[program.title !== '' ? 'text-success' : '']"
                :header="$t('program name') + ' - ' + program.locale"
              >
                <div class="form-group mb-0">
                  <input
                    type="text"
                    :placeholder="$t('name')"
                    class="form-control"
                    :id="`name${index}`"
                    v-model="program.name"
                  />
                  <!-- Add any additional content specific to each tab here -->
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("tax_number") }} </label>
            <input
              type="number"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('tax_number')"
              v-model="tax_number"
            />
          </div>
        </div>

        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("disntaion_number") }} </label>
            <input
              type="number"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('disntaion_number')"
              v-model="disntaion_number"
            />
          </div>
        </div>
        <div class="col-md-6 mb-6">
          <img alt="img" v-if="image" :src="image" />
          <!-- <input type="file" id="file" @change="imageUpload" multiple hidden /> -->
          <input
            type="file"
            id="file"
            @change="imageUpload($event)"
            multiple
            hidden
          />
          <label for="file" class="add_file"
            ><i class="fa-solid fa-upload"></i> {{ $t("edit_image") }}</label
          >
          <p>{{ this.imageCompanyName }}</p>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("edit") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Loading from "@/components/LottieFiles/loading.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default {
  name: "editCompany",
  components: {
    Multiselect,
    Loading,
    TabView,
    TabPanel,
  },
  data() {
    return {
      name_en: "",
      name_ar: "",
      name_id: "",
      code: "",
      program_name: [],
      image: "",
      countryValue: "",
      regularCountryValue: "", // For the regular country selection
      countries: [],
      cityValues: [],
      names: [],
      regularCityValues: [], // For the regular city selection
      cities: [],
      country_id: "",
      city_id: "",
      useCustomCitySelect: false,
      imageCompany: "",
      imageCompanyName: "",
      image_preview: "",
      tax_number: "",
      disntaion_number: "",
      loading: false,
      languages: ["ar", "en", "id"],
    };
  },
  methods: {
    deleteName(index) {
      // Check if deleting the item will result in removing the last 'ar', 'en', or 'id' locales
      const locales = ["ar", "en", "id"];
      const remainingLocales = this.program_name
        .filter((_, i) => i !== index)
        .map((item) => item.locale);

      // Ensure at least one of each required locale is still present
      const isDeletionAllowed = locales.every((locale) =>
        remainingLocales.includes(locale),
      );

      if (isDeletionAllowed) {
        this.program_name.splice(index, 1);
      } else {
        // Optionally, you can provide feedback to the user
      }
    },
    set_program_name() {
      this.languages.forEach((ele) => {
        this.program_name.push({
          lang: ele,
          name: "",
        });
      });
    },
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    // image
    imageUpload(event) {
      this.fileData = event.target.files[0];
      const reader = new FileReader();
      const name = this.imageCompany.name;
      reader.readAsDataURL(this.fileData);
      reader.onload = () => {
        this.imageCompanyName = name;
        this.image = reader.result;
      };
    },

    fetchCompany() {
      let id = this.$route.params.id;
      axios.get("/flight_companies/" + id).then(({ data }) => {
        // this.name_ar = data.data.name;
        // this.name_en = data.data.names[0].name;
        // this.name_id = data.data.names[2].name;
        this.program_name = data.data.names;
        // console.log(this.program_name, "program_name");
        this.names = data.data.names;
        this.code = data.data.code;
        this.countryValue = data.data.country;
        this.cityValues = data.data.city;
        this.regularCityValues = data.data.city; // For regular city selection
        this.image = data.data.image;
        this.tax_number = data.data.tax_number;
        this.disntaion_number = data.data.disntaion_number;
        // console.log(this.name_ar);
      });
    },

    async editCompany() {
      let id = this.$route.params.id;
      const formData = new FormData();
      this.program_name.forEach((ele) => {
        formData.append(`name_${ele.locale}`, ele.name);
      });
      // formData.append("name_ar", this.name_ar);
      // formData.append("name_en", this.name_en);
      // formData.append("name_id", this.name_id);
      formData.append("code", this.code);
      if (this.countryValue) {
        formData.append("country_id", this.countryValue.id);
      }

      formData.append("_method", "PUT");
      // params.append("city_id", this.cityValues.id);

      if (this.fileData instanceof File) {
        formData.append("image", this.fileData);
        // console.log(this.fileData);
        // myVariable is a file
      } else {
        // myVariable is not a file
      }
      this.loading = true; //the loading begin
      axios
        .post(`/flight_companies/${id}`, formData)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.loading = false; //the loading ended
          this.$router.push("/flight-company");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
  mounted() {
    this.fetchCompany();
    this.fetch_country();
    this.set_program_name();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
}
</style>
