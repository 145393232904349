<template>
  <section class="card_style mt-0">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="mb-0">
        <span v-if="blog_id">{{ $t("edit blog") }}</span>
        <span v-else>{{ $t("add blog") }}</span>
      </h4>
    </div>

    <form @submit.prevent="addBlog()">
      <div class="row">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="imageEntry" class="fw-bold fs-4">
                {{ $t("upload image") }}</label
              >
              <p>
                {{ $t("image size should be 400 * 300") }}
              </p>
              <input
                type="file"
                name="imageEntry"
                id="imageEntry"
                class="form-control w-50"
                @change="onFileChange"
                accept="image/png, image/gif, image/jpeg, image/jpg"
              />
              <span
                class="text-danger"
                v-if="v$.imageEntry.$error"
                v-text="v$.imageEntry.$errors[0].$message"
              ></span>
            </div>
            <img :src="imageEntry" class="blog_img w-25" />
          </div>
        </div>
        <div class="col-lg-4 mx-2 col-md-4 col-12" v-if="type === 'website'">
          <div class="form-group">
            <label for="">{{ $t("categotey-blogs") }}</label>
            <!-- {{ categoryBlogs }} -->
            <Multiselect
              v-model="categoryBlogs"
              :options="categoriesBlogs"
              :clear-on-select="true"
              :placeholder="$t('categotey-blogs')"
              label="title"
              track-by="id"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
<!--            <span-->
<!--              class="text-danger"-->
<!--              v-if="v$.categoryBlogs.$error"-->
<!--              v-text="v$.categoryBlogs.$errors[0].$message"-->
<!--            ></span>-->
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12" v-if="type === 'website'">
          <div class="form-group">
            <label for="">{{ $t("hastag-blogs") }}</label>
            <!-- {{ categoryBlogs }} -->
            <Multiselect
              v-model="hashTagBlogs"
              :options="hashtagsBlogs"
              :clear-on-select="true"
              :placeholder="$t('hastag-blogs')"
              label="title"
              track-by="id"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
<!--            <span-->
<!--              class="text-danger"-->
<!--              v-if="v$.hashTagBlogs.$error"-->
<!--              v-text="v$.hashTagBlogs.$errors[0].$message"-->
<!--            ></span>-->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.Form.title.$error"
              v-text="v$.Form.title.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("describtion") }}</label>
            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_content, index) in Form.subtitle"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{
                          blog_content.lang
                        }})</label
                      >
                      <Editor
                        v-model="blog_content.name"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.Form.subtitle.$error"
              v-text="v$.Form.subtitle.$errors[0].$message"
            ></span>
          </div>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Editor from "primevue/editor";
import Multiselect from "vue-multiselect";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "blogsWebsiteAdd",
  data() {
    return {
      v$: useVuelidate(),
      categoriesBlogs: [],
      categoryBlogs: "",
      hashtagsBlogs: [],
      hashTagBlogs: "",
      languages: ["ar", "en", "id"],
      options: {
        placeholder: this.$t("describtion"),
        theme: "snow",
      },
      Form: {
        title: [],
        subtitle: [],
      },
      imageEntry: "",
      defaultImage: require("@/assets/media/image/user.png"),
      blog_id: undefined,
      type: this.$route.params.type,
    };
  },
  components: {
    Editor,
    Multiselect,
    AddFavDailog,
  },
  validations() {
    return {
      imageEntry: {
        required,
      },
      // categoryBlogs: {
      //   required,
      // },
      // hashTagBlogs: {
      //   required,
      // },
      Form: {
        title: this.Form.title.map(() => {
          return {
            name: {
              required,
            },
          };
        }),
        subtitle: this.Form.subtitle.map(() => {
          return {
            name: {
              required,
            },
          };
        }),
      },
    };
  },
  methods: {
    setBlogTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          lang: language,
          name: "",
        });
        this.Form.subtitle.push({
          lang: language,
          name: "",
        });
      });
    },
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    addBlog() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        if(this.$route.params.type === "portal") {
          formData.append("is_portal", 1);
        }
        this.Form.title.forEach((ele) => {
          formData.append(`title_${ele.lang}`, ele.name);
        });
        this.Form.subtitle.forEach((ele) => {
          formData.append(`content_${ele.lang}`, ele.name);
        });

        if(this.categoryBlogs) {
          formData.append("blog_category_id", this.categoryBlogs.id);
        }

        if (this.hashTagBlogs.length > 0) {
          this.hashTagBlogs.forEach((ele, index) => {
            // console.log(index);
            formData.append(`hashtag_blog_ids[${index}]`, ele.id);
          });
        }

        if (this.$route.params.id !== undefined) {
          if (this.fileData instanceof File) {
            formData.append("image", this.fileData);
            // console.log(this.fileData)
            // myVariable is a file
          } else {
            // myVariable is not a file
          }
          formData.append("blog_id", this.blog_id);

          axios
            .post("/website/blogs/update", formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push(`/blogs/${this.$route.params.type}`);
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error,
                icon: "error",
              });
            });
        } else {
          if (this.fileData !== undefined) {
            formData.append("image", this.fileData);
          }

          axios
            .post(`/website/blogs`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push(`/blogs/${this.$route.params.type === "portal" ? "portal" : "website"}`);
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error,
                icon: "error",
              });
            });
        }
      }
    },
    fetchBlog() {
      if (this.$route.params.id !== undefined) {
        this.blog_id = this.$route.params.id;
        axios.get(`/fetch_blog/${this.blog_id}`).then(({ data }) => {
          this.Form.title = data.data.titles.map((ele) => {
            return { lang: ele.locale, name: ele.title };
          });
          this.Form.subtitle = data.data.contents.map((ele) => {
            return { lang: ele.locale, name: ele.content };
          });
          this.imageEntry = data.data.image;
          this.categoryBlogs = data.data.blog_category;
          this.hashTagBlogs = data.data.hashtag_blogs;
        });
      }
    },
    fetch_categories() {
      axios.post("/index_blog_categories").then(({ data }) => {
        this.categoriesBlogs = data.data.data;
        // console.log(data.data.data);
      });
    },
    hashtag_categories() {
      axios.post(`/index_hashtag_blogs`).then(({ data }) => {
        this.hashtagsBlogs = data.data.data;
        // console.log(this.hashtagsBlogs);
      });
    },
  },
  created() {
    this.setBlogTitle();
    this.fetchBlog();
    this.fetch_categories();
    this.hashtag_categories();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.blog_img {
  max-height: 300px;
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
</style>
