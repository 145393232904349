<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style rate_code" v-else>
    <div class="main_header_style">
      <h4>
        {{ $t("Rate code") }} / {{ fathy.name }} / {{ fathy.contract }} /{{
          fathy.hotel_name
        }}
      </h4>
    </div>

    <form class="form_topic w-100" @submit.prevent="addRateCode()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("name_rate") }}</label>

            <input
              type="text"
              class="form-control"
              :placeholder="$t('name_rate')"
              v-model="name"
              required
            />
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <div class="d-flex justify-content-between w-100">
              <label class="form-label"> {{ $t("country") }}</label>
              <div
                class="col-6 form-group justify-content-end gap-2 d-flex align-items-center"
              >
                <label for="nation" class="mb-0"
                  >{{ allNations ? $t("all_nations") : $t("choose_country") }}
                </label>
                <InputSwitch v-model="allNations" class="ml-2" id="nation" />
              </div>
            </div>
            <Multiselect
              v-model="country_id"
              :options="countries.map((country) => country.id)"
              :custom-label="(opt) => countries.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :multiple="true"
              :hide-selected="true"
              :disabled="allNations"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("From") }}</label>
            <datepicker
              v-model="fromDate"
              name="durationFrom"
              :placeholder="$t('From')"
              :disabled-dates="{
                to: new Date(fromDate),
                from: new Date(toDate),
              }"
            ></datepicker>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("To") }}</label>
            <datepicker
              v-model="toDate"
              name="durationTo  "
              :placeholder="$t('To')"
              :disabled-dates="{
                to: new Date(fromDate),
                from: new Date(toDate),
              }"
            ></datepicker>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("employ") }}</label>
            <Multiselect
              v-model="employees_id"
              :options="empolyment"
              label="name"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :multiple="true"
              :hide-selected="true"
            ></Multiselect>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("rooms") }}</label>
            <Multiselect
              v-model="hotelRoom"
              :options="hotelRooms"
              label="name"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :multiple="true"
              :custom-label="hotelRoomName"
              :hide-selected="true"
              @remove="handleRemove"
            ></Multiselect>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("meals") }}</label>
            <Multiselect
              v-model="meal"
              :options="meals"
              label="title"
              track-by="meal_type_id"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :multiple="true"
              :hide-selected="true"
              @remove="handleRemoveMeals"
            ></Multiselect>
          </div>
        </div>
        <!-- Rooms-->
        <h2 v-if="hotelRoomArray.length > 0">{{ $t("rooms") }}</h2>
        <div class="meals_section rooms d-flex gap-3 mb-3">
          <div
            class="card"
            v-for="(room, index) in hotelRoomArray"
            :key="index"
          >
            <h4>{{ $t("Type Of Room") }} {{ room.title }}</h4>
            <div class="row align-items-center">
              <p class="col-4 text">{{ $t("cost") }}</p>
              <div class="col-4 form-group">
                <label
                  :for="`selling${room.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${room.id}`"
                  class="form-control"
                  placeholder="0"
                  v-model="room.week_day_cost"
                />
              </div>

              <div class="col-4 form-group">
                <label
                  :for="`selling${room.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${room.id}`"
                  class="form-control"
                  placeholder="0"
                  v-model="room.week_end_cost"
                />
              </div>
              <p class="col-4 text gap-2 mb-0">
                <span>
                  <Checkbox v-model="room.switchB2c" :binary="true" />
                </span>
                {{ $t("selling_b2c") }}
              </p>
              <div class="col-4 form-group">
                <label
                  :for="`selling${room.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${room.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="!room.switchB2c"
                  v-model="room.week_day_b2c_price"
                />
              </div>
              <div class="col-4 form-group">
                <label
                  :for="`selling${room.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${room.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="!room.switchB2c"
                  v-model="room.week_end_b2c_price"
                />
              </div>

              <p class="col-4 text gap-2 mb-0">
                <span>
                  <Checkbox v-model="room.switchB2b" :binary="true" />
                </span>
                {{ $t("selling_b2b") }}
              </p>
              <div class="col-4 form-group">
                <label
                  :for="`selling${room.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${room.id}`"
                  class="form-control"
                  placeholder="b2b"
                  :disabled="!room.switchB2b"
                  v-model="room.week_day_b2b_price"
                />
              </div>
              <div class="col-4 form-group">
                <label
                  :for="`selling${room.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  class="form-control"
                  placeholder="b2b"
                  :disabled="!room.switchB2b"
                  v-model="room.week_end_b2b_price"
                  :id="`cost${room.id}`"
                />
              </div>
            </div>
          </div>
        </div>
        <hr class="my-1" v-if="hotelViewArray.length > 0" />
        <!-- views -->
        <h2
          class="mt-2 col-12 d-flex gap-2 align-items-center"
          v-if="hotelViewArray.length > 0"
        >
          <span>{{ $t("Views") }}</span>
          <span class="fs-6 text-secondary d-flex gap-2 align-items-center">
            {{ $t("One Selling Price") }} <InputSwitch v-model="checkedView" />
          </span>
        </h2>
        <div class="meals_section views d-flex gap-3 mb-3">
          <div
            class="card"
            v-for="(view, index) in hotelViewArray"
            :key="index"
          >
            <div class="row align-items-center">
              <h4 class="col-6">{{ view.title }}</h4>
              <div
                class="col-6 form-group justify-content-end gap-2 d-flex align-items-center"
              >
                <label for="include" class="mb-0"
                  >{{ view.include ? $t("includes") : $t("excludes") }}
                </label>
                <InputSwitch
                  v-model="view.include"
                  class="ml-2"
                  :trueValue="updateViewInclude(view)"
                  :id="`include${index}`"
                />
              </div>
              <p class="col-2 text">{{ $t("cost") }}</p>
              <div class="col-5 form-group">
                <label
                  :for="`selling${view.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${view.id}`"
                  class="form-control"
                  placeholder="0"
                  :disabled="view.include"
                  v-model="view.week_day_cost"
                />
              </div>

              <div class="col-5 form-group">
                <label
                  :for="`selling${view.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${view.id}`"
                  class="form-control"
                  placeholder="0"
                  :disabled="view.include"
                  v-model="view.week_end_cost"
                />
              </div>

              <p class="col-2 text gap-2 mb-0" v-show="!checkedView">
                {{ $t("selling_b2c") }}
              </p>
              <div class="col-5 form-group" v-show="!checkedView">
                <label
                  :for="`selling${view.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${view.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="view.include"
                  v-model="view.week_day_b2c_price"
                />
              </div>
              <div class="col-5 form-group" v-show="!checkedView">
                <label
                  :for="`selling${view.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${view.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="view.include"
                  v-model="view.week_end_b2c_price"
                />
              </div>

              <p class="col-2 text gap-2 mb-0" v-show="!checkedView">
                {{ $t("selling_b2b") }}
              </p>
              <div class="col-5 form-group" v-show="!checkedView">
                <label
                  :for="`selling${view.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${view.id}`"
                  class="form-control"
                  :disabled="view.include"
                  placeholder="b2b"
                  v-model="view.week_day_b2b_price"
                />
              </div>
              <div class="col-5 form-group" v-show="!checkedView">
                <label
                  :for="`selling${view.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  class="form-control"
                  placeholder="b2b"
                  :disabled="view.include"
                  v-model="view.week_end_b2b_price"
                  :id="`cost${view.id}`"
                />
              </div>
              <div
                class="total row m-0 p-0 align-items-center"
                v-show="checkedView"
              >
                <p class="col-2 text gap-2 mb-0">
                  {{ $t("selling") }}
                </p>
                <div class="col-5 form-group">
                  <label
                    :for="`selling${view.id}`"
                    class="form-label text-info"
                    >{{ $t("Weekday") }}</label
                  >
                  <input
                    type="number"
                    name=""
                    :id="`selling${view.id}`"
                    class="form-control"
                    placeholder="b2b"
                    :disabled="!checkedView || view.include"
                    @input="totalB2bB2cDay(view)"
                    v-model="view.totalB2bB2cDay"
                  />
                </div>
                <div class="col-5 form-group">
                  <label
                    :for="`selling${view.id}`"
                    class="form-label text-success"
                    >{{ $t("Weekend") }}</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    placeholder="b2b"
                    :disabled="!checkedView || view.include"
                    v-model="view.totalB2bB2cEnd"
                    @input="totalB2bB2cEnd(view)"
                    :id="`cost${view.id}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-1" v-if="mealsArray.length > 0" />
        <!-- Meals -->
        <h2
          v-if="mealsArray.length > 0"
          class="d-flex align-items-center my-3 gap-3"
        >
          <span>{{ $t("meals") }}</span>
          <span class="fs-6 text-secondary d-flex gap-2 align-items-center">
            {{ $t("One Selling Price") }}
            <InputSwitch
              :trueValue="updateMealInclude(meal)"
              v-model="checkedMeal"
              class="ml-2"
            />
          </span>
        </h2>
        <div class="meals_section meals d-flex gap-3 mb-3">
          <div class="card" v-for="(meal, index) in mealsArray" :key="index">
            <div class="row">
              <h4 class="col-6">{{ meal.title }}</h4>
              <div
                class="col-6 form-group justify-content-end gap-2 d-flex align-items-center"
              >
                <label for="include" class="mb-0"
                  >{{ meal.include ? $t("includes") : $t("excludes") }}
                </label>
                <InputSwitch
                  v-model="meal.include"
                  class="ml-2"
                  :id="`include${index}`"
                />
              </div>
            </div>
            <div class="row align-items-center">
              <p class="col-2 text">{{ $t("cost") }}</p>
              <div class="col-5 form-group">
                <label
                  :for="`selling${meal.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${meal.id}`"
                  class="form-control"
                  placeholder="0"
                  :disabled="meal.include"
                  v-model="meal.week_day_cost"
                />
              </div>

              <div class="col-5 form-group">
                <label
                  :for="`selling${meal.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${meal.id}`"
                  class="form-control"
                  placeholder="0"
                  :disabled="meal.include"
                  v-model="meal.week_end_cost"
                />
              </div>
              <p class="col-2 text gap-2 mb-0" v-show="!checkedMeal">
                {{ $t("selling_b2c") }}
              </p>
              <div class="col-5 form-group" v-show="!checkedMeal">
                <label
                  :for="`selling${meal.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${meal.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="meal.include"
                  v-model="meal.week_day_b2c_price"
                />
              </div>
              <div class="col-5 form-group" v-show="!checkedMeal">
                <label
                  :for="`selling${meal.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${meal.id}`"
                  class="form-control"
                  placeholder="b2c"
                  :disabled="meal.include"
                  v-model="meal.week_end_b2c_price"
                />
              </div>

              <p class="col-2 text gap-2 mb-0" v-show="!checkedMeal">
                {{ $t("selling_b2b") }}
              </p>
              <div class="col-5 form-group" v-show="!checkedMeal">
                <label
                  :for="`selling${meal.id}`"
                  class="form-label text-info"
                  >{{ $t("Weekday") }}</label
                >
                <input
                  type="number"
                  name=""
                  :id="`selling${meal.id}`"
                  class="form-control"
                  placeholder="b2b"
                  :disabled="meal.include"
                  v-model="meal.week_day_b2b_price"
                />
              </div>
              <div class="col-5 form-group" v-show="!checkedMeal">
                <label
                  :for="`selling${meal.id}`"
                  class="form-label text-success"
                  >{{ $t("Weekend") }}</label
                >
                <input
                  type="number"
                  class="form-control"
                  placeholder="b2b"
                  :disabled="meal.include"
                  v-model="meal.week_end_b2b_price"
                  :id="`cost${meal.id}`"
                />
              </div>
              <div
                class="total row m-0 p-0 align-items-center"
                v-show="checkedMeal"
              >
                <p class="col-2 text gap-2 mb-0">
                  {{ $t("selling") }}
                </p>
                <div class="col-5 form-group">
                  <label
                    :for="`selling${meal.id}`"
                    class="form-label text-info"
                    >{{ $t("Weekday") }}</label
                  >
                  <input
                    type="number"
                    name=""
                    :id="`selling${meal.id}`"
                    class="form-control"
                    placeholder="b2b"
                    :disabled="!checkedMeal || meal.include"
                    @input="totalB2bB2cDay(meal)"
                    v-model="meal.totalB2bB2cDay"
                  />
                </div>
                <div class="col-5 form-group">
                  <label
                    :for="`selling${meal.id}`"
                    class="form-label text-success"
                    >{{ $t("Weekend") }}</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    placeholder="b2b"
                    :disabled="!checkedMeal || meal.include"
                    v-model="meal.totalB2bB2cEnd"
                    @input="totalB2bB2cEnd(meal)"
                    :id="`cost${meal.id}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <TabView>
              <TabPanel
                v-for="(term, index) in Form.terms"
                :key="index"
                :class="[term.title !== '' ? 'text-success' : '']"
                :header="$t('terms') + ' - ' + term.lang"
              >
                <div class="form-group mb-0">
                  <Editor v-model="term.name" editorStyle="height: 120px" />
                  <!-- Add any additional content specific to each tab here -->
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>

        <hr class="my-1" />
        <!-- finish input group -->

        <!-- start input group -->

        <!-- finish input group -->

        <!-- types room -->
        <!-- <ul class="types">
          <li v-for="(type, index) in service_types" :key="index">
            <div class="form-group service_type">
              <input
                type="radio"
                name="service_type"
                class="wizard-required"
                v-model="selectedServiceType"
                :value="type.value"
                :id="'default' + type.value"
              />
              <label :for="'default' + type.value">
                {{ $t(getTypeName(type.value)) }}
              </label>
            </div>
          </li>
        </ul> -->
        <!-- types room -->

        <!-- rooms -->
        <!-- <div class="col-12">
          <div class="available_rooms">
            <div class="not_available" v-if="!rooms.length">
              <img
                src="@/assets/media/image/available_rooms.png"
                alt="available_rooms"
              />
              <h5 class="text">
                {{ $t("Enter the required data to get the available rooms") }}
              </h5>
            </div>
            <div
              class="rooms"
              v-else
              v-for="(room, index) in rooms"
              :key="index"
            >
              <div class="room_details">
                <div class="details">
                  <p class="title">{{ room.title }}</p>
                </div>
                <div class="price_quantity">
                  <div class="quantity">
                    <button
                      class="btn minus"
                      type="button"
                      @click="minusQuantity(index)"
                    >
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <span class="number">{{ room.quantity }}</span>
                    <button
                      class="btn add"
                      type="button"
                      @click="addQuantity(index)"
                    >
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="details_price" v-if="room.quantity >= 1">
                <div class="row">
                  <div class="col-lg-4 col-md-5 col-12">
                    <div class="form-group">
                      <label :for="`selling${room.id}`">{{
                        $t("cost_week_end")
                      }}</label>
                      <input
                        type="number"
                        name=""
                        :id="`selling${room.id}`"
                        class="form-control"
                        placeholder="0"
                        v-model="room.week_day_cost"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-5 col-12">
                    <div class="form-group">
                      <label :for="`cost${room.id}`">{{
                        $t("price_week_b2b")
                      }}</label>
                      <input
                        type="number"
                        class="form-control"
                        v-model="room.week_end_b2c_price"
                        :id="`cost${room.id}`"
                      />
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-5 col-12">
                    <div class="form-group">
                      <label :for="`selling${room.id}`">{{
                        $t("price_week_b2c")
                      }}</label>
                      <input
                        type="number"
                        name=""
                        :id="`selling${room.id}`"
                        class="form-control"
                        placeholder="0"
                        v-model="room.week_end_b2c_price"
                      />
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-5 col-12">
                    <div class="form-group">
                      <label :for="`selling${room.id}`">{{
                        $t("cost_week_day")
                      }}</label>
                      <input
                        type="number"
                        name=""
                        :id="`selling${room.id}`"
                        class="form-control"
                        placeholder="0"
                        v-model="room.week_day_cost"
                      />
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-5 col-12">
                    <div class="form-group">
                      <label :for="`selling${room.id}`">{{
                        $t("price_week_b2b_day")
                      }}</label>
                      <input
                        type="number"
                        name=""
                        :id="`selling${room.id}`"
                        class="form-control"
                        placeholder="0"
                        v-model="room.week_day_b2b_price"
                      />
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-5 col-12">
                    <div class="form-group">
                      <label :for="`selling${room.id}`">{{
                        $t("week_day_b2c_price")
                      }}</label>
                      <input
                        type="number"
                        name=""
                        :id="`selling${room.id}`"
                        class="form-control"
                        placeholder="0"
                        v-model="room.week_day_b2c_price"
                      />
                    </div>
                  </div>
                </div>
                <div class="details_room">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">{{ $t("date") }}</th>
                          <th
                            scope="col"
                            v-for="(day, item) in room.selectedDays"
                            :key="item"
                          >
                            <span>{{ formatDate(day.date) }}</span
                            ><span>{{ day.dayOfWeek }}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">{{ $t("cost") }}</th>
                          <td
                            v-for="(day, item) in room.selectedDays"
                            :key="item"
                          >
                            <input
                              type="number"
                              class="form-control"
                              v-model="day.cost"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{{ $t("selling_price_b2b") }}</th>
                          <td
                            v-for="(day, item) in room.selectedDays"
                            :key="item"
                          >
                            <input
                              type="number"
                              class="form-control"
                              v-model="day.selling_price_b2b"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{{ $t("selling_price_b2c") }}</th>
                          <td
                            v-for="(day, item) in room.selectedDays"
                            :key="item"
                          >
                            <input
                              type="number"
                              class="form-control"
                              v-model="day.selling_price_b2c"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-12">
                  <button
                    type="button"
                    class="btn PrimaryButton w-100 my-3"
                    @click="save_changes(index)"
                  >
                    {{ $t("save_changes") }}
                  </button>
                </div>
             
              </div>
            </div>
          </div>
        </div> -->
        <!-- rooms -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton">
            <i class="fa-solid fa-spinner fa-spin-pulse" v-if="addLoader"></i>
            <span v-else>
              {{ $t("add") }}
            </span>
          </button>
        </div>

        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
// import InputSwitch from "primevue/inputswitch";
import Loading from "@/components/LottieFiles/loading.vue";
import Checkbox from "primevue/checkbox";

import InputSwitch from "primevue/inputswitch";
import Editor from "primevue/editor";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import axios from "axios";
import Swal from "sweetalert2";
import Datepicker from "vuejs3-datepicker";
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
  name: "add-season",
  components: {
    // InputSwitch,
    Datepicker,
    Multiselect,
    Loading,
    Checkbox,
    InputSwitch,
    Editor,
    TabView,
    TabPanel,
  },
  data() {
    return {
      languages: ["ar", "en", "id"],

      Form: {
        terms: [],
      },
      name: "",
      addLoader: false,
      loading: true,
      checked: true,
      duration: null,
      checkedView: false,
      checkedMeal: false,
      meals: [],
      meal: "",
      hotelRoomArray: [],
      hotelViewArray: [],
      countries: [],
      country_id: [],
      selectedServiceType: 1,
      service_types: [{ value: 1 }, { value: 2 }, { value: 3 }],
      sellingApply: false,
      reservation_number: "",
      selectedRooms: [],
      rooms: [],
      start_date: null,
      end_date: null,
      hotel_id: null,
      // meals: [],
      fromDate: null,
      toDate: null,
      number_of_nights: null,
      contract_weekends: [],
      week_end: [],
      season_id: this.$route.params.season_id,
      fathy: [{}],
      hotelRooms: [],
      hotelRoom: "",
      mealsArray: [],
      watchVariable: true,
      hotelWatch: true,
      defaultData: null,
      defaultMeals: null,
      allNations: true,
      empolyment: [],
      employees_id: [],
    };
  },
  computed: {
    selectedDays() {
      const selectedDays = [];
      if (this.fromDate && this.toDate && this.number_of_nights > 0) {
        const fromDate = new Date(this.fromDate);
        for (let i = 0; i < this.number_of_nights; i++) {
          const currentDate = new Date(fromDate);
          currentDate.setDate(fromDate.getDate() + i);
          selectedDays.push({
            date: currentDate.toISOString().split("T")[0],
            dayOfWeek: this.getDayOfWeek(currentDate.getDay()),
          });
        }
      }
      return selectedDays;
    },
  },
  watch: {
    checked: {
      immediate: false,
      handler() {
        this.fetchRooms();
      },
    },
    hotelRoom: {
      immediate: false,
      handler(newValue, oldValue) {
        if (this.hotelWatch) {
          this.hotelWatch = false;
          return;
        } else {
          if (Array.isArray(oldValue)) {
            // Handle deletions
            const deletedRooms = oldValue.filter(
              (oldItem) =>
                oldItem.room.id !== this.defaultData.room.id &&
                !newValue.some(
                  (newItem) => oldItem.room.id === newItem.room.id,
                ),
            );
            // Remove deleted rooms from the array
            deletedRooms.forEach((deletedItem) => {
              const index = this.hotelRoomArray.findIndex(
                (room) => room.id === deletedItem.room.id,
              );
              if (index !== -1) {
                this.hotelRoomArray.splice(index, 1);
              }
            });

            // Find deleted views
            const deletedViews = oldValue.filter(
              (oldItem) =>
                oldItem.view_type.id !== this.defaultData.view_type.id &&
                !newValue.some(
                  (newItem) => oldItem.view_type.id === newItem.view_type.id,
                ),
            );
            // Remove deleted views from the array
            deletedViews.forEach((deletedItem) => {
              const index = this.hotelViewArray.findIndex(
                (view) => view.id === deletedItem.view_type.id,
              );
              if (index !== -1) {
                this.hotelViewArray.splice(index, 1);
              }
            });
          }

          // Clear hotelViewArray if hotelRoomArray is empty
          if (this.hotelRoomArray.length === 0) {
            this.hotelViewArray = [];
          }

          // Handle additions
          newValue.forEach((selectedItem) => {
            const exists = this.hotelRoomArray.some(
              (room) => room.id === selectedItem.room.id,
            );
            if (!exists) {
              const roomWithAdditionalProps = {
                ...selectedItem.room,
                week_day_cost: 0,
                week_end_cost: 0,
                week_end_b2b_price: 0,
                week_day_b2b_price: 0,
                week_end_b2c_price: 0,
                week_day_b2c_price: 0,
                switchB2b: true,
                switchB2c: true,
              };

              this.hotelRoomArray.push(roomWithAdditionalProps);
            }
            const viewExists = this.hotelViewArray.some(
              (view) => view.id === selectedItem.view_type.id,
            );
            if (!viewExists) {
              const roomWithAdditionalProps = {
                ...selectedItem.view_type,
                week_day_cost: 0,
                include: false,
                week_end_cost: 0,
                week_end_b2b_price: 0,
                week_day_b2b_price: 0,
                week_end_b2c_price: 0,
                week_day_b2c_price: 0,
                switchB2b: true,
                switchB2c: true,
                totalB2bB2cEnd: 0,
                totalB2bB2cDay: 0,
              };
              this.hotelViewArray.push(roomWithAdditionalProps);
            }
          });
        }
      },
    },

    meal: {
      immediate: false,
      handler(newValue, oldValue) {
        if (this.watchVariable) {
          this.watchVariable = false;
          return;
        } else {
          if (Array.isArray(oldValue)) {
            // Handle deletions
            const deletedItems = oldValue.filter(
              (oldItem) =>
                oldItem.meal_type_id !== this.defaultMeals.meal_type_id &&
                !newValue.some(
                  (newItem) => oldItem.meal_type_id === newItem.meal_type_id,
                ),
            );
            // Remove deleted items from the array
            deletedItems.forEach((deletedItem) => {
              const index = this.mealsArray.findIndex(
                (meal) => meal.meal_type_id === deletedItem.meal_type_id,
              );
              if (index !== -1) {
                this.mealsArray.splice(index, 1);
              }
            });
          }

          // Handle additions
          newValue.forEach((selectedItem) => {
            const exists = this.mealsArray.some(
              (meal) => meal.meal_type_id === selectedItem.meal_type_id,
            );
            if (!exists) {
              const mealWithAdditionalProps = {
                ...selectedItem,
                include: false,
                week_day_cost: 0,
                week_end_cost: 0,
                week_end_b2b_price: 0,
                week_day_b2b_price: 0,
                week_end_b2c_price: 0,
                week_day_b2c_price: 0,
                totalB2bB2cEnd: 0,
                totalB2bB2cDay: 0,
              };
              this.mealsArray.push(mealWithAdditionalProps);
              // console.log(this.mealsArray);
            }
          });
        }
      },
    },
  },
  methods: {
    handleRemove(selectedItem) {
      // Check if the removed item is the defaultData
      if (
        selectedItem &&
        selectedItem.room &&
        selectedItem.view_type &&
        selectedItem.room.id === this.defaultData.room.id &&
        selectedItem.view_type.id === this.defaultData.view_type.id
      ) {
        // If it is the default data, prevent removal
        this.$nextTick(() => {
          this.hotelRoom.push(selectedItem); // Add the default data back to the selection
        });
        Swal.fire({
          icon: "error",
          title: this.$t("error"),
          text: this.$t("cannot_remove_default_data"),
        });
      }
    },
    updateMealInclude(meal) {
      if (meal.include) {
        meal.week_day_b2b_price = 0;
        meal.week_day_b2c_price = 0;
        meal.week_day_cost = 0;
        meal.week_end_b2b_price = 0;
        meal.week_end_b2c_price = 0;
        meal.week_end_cost = 0;
      }
    },
    handleRemoveMeals(selectedItem) {
      console.log("Selected Item meal_type_id:", selectedItem.meal_type_id);
      console.log(
        "Default Meals meal_type_id:",
        this.defaultMeals.meal_type_id,
      );

      // Check if the removed item is the defaultData
      if (
        selectedItem &&
        selectedItem.meal_type_id === this.defaultMeals.meal_type_id
      ) {
        // If it is the default data, prevent removal
        this.$nextTick(() => {
          this.meal.push(selectedItem); // Add the default data back to the selection
        });
        Swal.fire({
          icon: "error",
          title: this.$t("error"),
          text: this.$t("cannot_remove_default_data"),
        });
      }
    },

    FetchEmploy() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/organization_employees").then(({ data }) => {
        this.empolyment = data.data.data;
        this.loading = false;
        //  console.log(this.empolyment)
      });
    },

    hotelRoomName(value) {
      return `${value.room.title} - ${value.view_type.title}`;
    },
    totalB2bB2cEnd(value) {
      value.week_end_b2c_price = value.totalB2bB2cEnd;

      value.week_end_b2b_price = value.totalB2bB2cEnd;
    },
    totalB2bB2cDay(value) {
      value.week_day_b2b_price = value.totalB2bB2cDay;
      value.week_day_b2c_price = value.totalB2bB2cDay;
    },
    //fetch country
    async fetch_country() {
      await axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    getTypeName(value) {
      // Define mappings of value to type names
      const nameMap = {
        1: "all_rooms",
        2: "rooms",
        3: "sweets",
        // ... add more mappings as needed ...
      };
      return nameMap[value];
    },
    // minusQuantity(index) {
    //   if (this.rooms[index].quantity > 0) {
    //     this.rooms[index].quantity -= 1;
    //   }
    //   if (this.rooms[index].quantity == 0) {
    //     this.rooms[index].selected = false;
    //   }
    // },
    // addQuantity(index) {
    //   this.rooms[index].quantity += 1;
    //   this.rooms[index].selected = true;
    // },
    // formatDate(value) {
    //   return moment(value).format("DD- MM");
    // },
    get_rate_codes(contract_id) {
      axios
        .get(`/get_rate_codes_by_contract_id?contract_id=${contract_id}`)
        .then(({ data }) => {
          this.rateCode = data.data;
        });
    },
    resetRooms() {
      this.rooms = [];
      this.hotelValue = "";
      this.country_id = "";
      this.contractValue = "";
      this.rateValue = "";
    },
    updateViewInclude(view) {
      if (view.include) {
        view.week_day_b2b_price = 0;
        view.week_day_b2c_price = 0;
        view.week_day_cost = 0;
        view.week_end_b2b_price = 0;
        view.week_end_b2c_price = 0;
        view.week_end_cost = 0;
      }
    },

    // fetch start && end seasons
    fetch_seasons() {
      let season_id = this.$route.params.season_id;
      // console.log(id);
      axios.get("/seasons/" + season_id).then(({ data }) => {
        this.fathy = data.data;
        this.fromDate = data.data.start_date;
        this.toDate = data.data.end_date;
        // console.log(data.data);
      });
    },

    // fetch hotel id by contracts

    // cal differnce betwwen two dates

    fetchHotelRooms(id) {
      axios
        .post("/fetch_custome_hotel_room_view", { hotel_id: id })
        .then(({ data }) => {
          this.hotelRooms = data.data;
        });
    },
    async fetch_Contracts() {
      try {
        let id = this.$route.params.id;
        await axios.get("/contracts/" + id).then(({ data }) => {
          // console.log(data)
          this.hotel_id = data.data.hotel.id;
          this.hotelRoom = data.data.default_rooms;
          this.defaultData = data.data.default_rooms;

          let defaultRoom = null;
          defaultRoom = data.data.default_rooms.room;
          const roomWithAdditionalProps = {
            week_day_cost: 0,
            week_end_cost: 0,
            week_end_b2b_price: 0,
            week_day_b2b_price: 0,
            week_end_b2c_price: 0,
            week_day_b2c_price: 0,
            switchB2b: true,
            switchB2c: true,
            totalB2bB2cEnd: 0,
            totalB2bB2cDay: 0,
          };
          Object.assign(defaultRoom, roomWithAdditionalProps);
          this.hotelRoomArray.push(defaultRoom);
          // console.log(this.hotelViewArray);
          let defaultView = null;
          defaultView = data.data.default_rooms.view_type;
          const roomWithAdditionalProps2 = {
            week_day_cost: 0,
            week_end_cost: 0,
            week_end_b2b_price: 0,
            week_day_b2b_price: 0,
            week_end_b2c_price: 0,
            week_day_b2c_price: 0,
            switchB2b: true,
            switchB2c: true,
            totalB2bB2cEnd: 0,
            totalB2bB2cDay: 0,
            include: true,
          };
          Object.assign(defaultView, roomWithAdditionalProps2);
          this.hotelViewArray.push(defaultView);
          // console.log(this.hotelRoomArray);
          this.meal = data.data.default_meal;
          let defaultMeal = null;
          defaultMeal = data.data.default_meal;
          this.defaultMeals = data.data.default_meal;
          const mealWithAdditionalProps = {
            include: false,
            week_day_cost: 0,
            week_end_cost: 0,
            week_end_b2b_price: 0,
            week_day_b2b_price: 0,
            week_end_b2c_price: 0,
            week_day_b2c_price: 0,
            totalB2bB2cEnd: 0,
            totalB2bB2cDay: 0,
          };
          Object.assign(defaultMeal, mealWithAdditionalProps);
          this.mealsArray.push(defaultMeal);
          // console.log(this.mealsArray);
          this.fetchHotelRooms(this.hotel_id);
          this.loading = false;
        });
        // console.log(this.hotel_id);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      }
    },
    // fetch room id by hotel id
    fetchRooms() {
      this.loading = true;
      try {
        let hotel_id = {
          hotel_id: this.hotel_id,
          source: this.checked == false ? 1 : 0,
        };
        // console.log("tttt");
        axios.post("/fetch_rooms_by_source", hotel_id).then(({ data }) => {
          this.loading = false;
          // console.log("tttt");
          console.log(data.data);
          // this.rooms = data.data.map((room) => {
          //   return {
          //     ...room,
          //     quantity: 0,
          //     selected: false,
          //     week_end_b2c_price: 0,
          //     week_day_cost: 0,
          //     week_end_b2c_price: 0,
          //     week_day_b2b_price: 0,
          //     week_day_cost: 0,
          //     week_day_b2c_price: 0,
          //     selectedDays: this.selectedDays.map((day) => {
          //       return {
          //         ...day,
          //         cost: 0,
          //         selling_price_b2b: 0,
          //         selling_price_b2c: 0,
          //       };
          //     }),
          //   };
          // });
        });
      } catch (error) {
        // console.log("test")
        this.loading = false;
        Swal.fire({
          text: this.$t("something went wrong"),
          icon: "error",
        });
      }
    },
    getDayOfWeek(dayIndex) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[dayIndex];
    },
    // save_changes(index) {
    //   this.rooms[index].selectedDays.forEach((day) => {
    //     day.selling_price_b2b = this.rooms[index].week_day_b2b_price;
    //     day.selling_price_b2c = this.rooms[index].week_day_b2c_price;
    //     day.cost = this.rooms[index].week_day_cost;
    //     this.week_end.forEach((weekEnd) => {
    //       if (day.dayOfWeek == weekEnd) {
    //         day.selling_price_b2b = this.rooms[index].week_end_b2c_price;
    //         day.selling_price_b2c = this.rooms[index].week_end_b2c_price;
    //         day.cost = this.rooms[index].week_day_cost;
    //       }
    //     });
    //   });
    // },
    async fetchMealsTypes() {
      await axios
        .post("/fetch_meal_types")
        .then(({ data }) => {
          this.meals = data.data.data;
          // console.log(this.meals , "meals");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    setFeatureTitle() {
      this.languages.forEach((language) => {
        this.Form.terms.push({
          lang: language,
          name: "",
        });
      });
    },
    addRateCode() {
      this.addLoader = true;
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("start_date", moment(this.fromDate).format("YYYY-MM-DD"));
      formData.append("end_date", moment(this.toDate).format("YYYY-MM-DD"));
      formData.append("season_id", this.season_id);
      // console.log(this.employees_id)
      for (let i = 0; i < this.employees_id.length; i++) {
        formData.append(`employees[${i}]`, this.employees_id[i].id);
      }
      if (this.allNations) {
        formData.append("has_nationality", 1);
      } else {
        formData.append("has_nationality", 0);
        this.country_id.forEach((id, index) => {
          formData.append(`country_ids[${index}]`, id);
        });
      }
      this.Form.terms.forEach((ele) => {
        formData.append(`term_${ele.lang}`, ele.name);
      });
      this.hotelRoomArray.forEach((room, index) => {
        Object.entries(room).forEach(([key, value]) => {
          if (key == "id") {
            formData.append(`rooms[${index}][room_id]`, value);
          } else {
            formData.append(`rooms[${index}][${key}]`, value);
          }
        });
      });
      this.hotelViewArray.forEach((view, index) => {
        Object.entries(view).forEach(([key, value]) => {
          if (key == "id") {
            formData.append(`view_types[${index}][view_type_id]`, value);
          } else if (key === "include") {
            if (value == true) {
              formData.append(`view_types[${index}][${key}]`, 1);
            } else {
              formData.append(`view_types[${index}][${key}]`, 0);
            }
          } else {
            formData.append(`view_types[${index}][${key}]`, value);
          }
        });
      });
      this.mealsArray.forEach((meal, index) => {
        Object.entries(meal).forEach(([key, value]) => {
          if (key == "include") {
            if (value == true) {
              formData.append(`meals[${index}][${key}]`, 1);
            } else {
              formData.append(`meals[${index}][${key}]`, 0);
            }
          } else {
            formData.append(`meals[${index}][${key}]`, value);
          }
        });
      });
      if (this.hotelRoom.length > 1) {
        this.hotelRoom.forEach((room, index) => {
          formData.append(`hotel_room_view_ids[${index}]`, room.id);
        });
      } else {
        formData.append("hotel_room_view_ids[0]", this.hotelRoom.id);
      }
      this.axios
        .post("/store_season_rate_code", formData)
        .then(({ data }) => {
          console.log(data);
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.addLoader = false;
          this.$router.push(`/contract/${this.$route.params.id}/seasons`);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error?.response?.data?.message,
            icon: "error",
          });
          this.addLoader = false;
        });
    },
  },

  mounted() {
    this.fetch_country();
    this.fetch_seasons();
    this.fetchMealsTypes();
    this.fetch_Contracts();
    this.FetchEmploy();
    this.setFeatureTitle();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_season.scss";
</style>
