<template>
  <section class="card_style">
    <div class="main_header">
      <h4 v-if="this.$route.params.id != undefined">
        {{ $t("edit client type") }}
      </h4>
      <h4 v-else>{{ $t("add client type") }}</h4>
    </div>
    <skeltonTitles v-if="this.$route.params.id && loading" />
    <form @submit.prevent="addService()" v-else>
      <div class="row">
        <!-- <h4>
            <i class="fa-solid fa-caret-left"></i>
            المرحله الاولي
          </h4> -->
        <div class="col-lg-4 col-md-4 col-12">
          <!-- <div class="form-group">
            <label>{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.locale }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.title"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.Form.title.$error">{{
              v$.Form.title.$errors[0].$message
            }}</span>
          </div> -->

          <div class="form-group">
            <TabView>
              <TabPanel
                v-for="(car, index) in clientType"
                :key="index"
                :class="[car.title !== '' ? 'text-success' : '']"
                :header="$t('title') + ' - ' + car.locale"
              >
                <div class="form-group mb-0">
                  <input
                    type="text"
                    :placeholder="$t('title')"
                    class="form-control"
                    :id="`title${index}`"
                    v-model="car.title"
                  />
                  <!-- Add any additional content specific to each tab here -->
                </div>
              </TabPanel>
            </TabView>
            <span class="input-errors" v-if="v$.clientType.$error">{{
              v$.clientType.$errors[0].$message
            }}</span>
          </div>
        </div>
        <br />
        <div class="col-lg-4 mt-4">
          <label for="type" class="fw-bold mb-4"
            >{{ $t("Manual code") }}

            <sub class="info">
              <b>( {{ $t("unique code for type client") }} )</b>
            </sub>
          </label>
          <input
            class="form-control"
            type="text"
            :placeholder="$t('Manual code')"
            v-model="manual_code"
          />
          <!-- <span class="input-errors" v-if="v$.manual_code.$error"
            >{{ v$.manual_code.$errors[0].$message }}
          </span> -->
        </div>
      </div>

      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import skeltonTitles from "@/components/skelton/skeltonTitles.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
export default {
  name: "add-service",
  data() {
    return {
      loading: true,
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      clientType: [],
      manual_code: "",
      fileData: null,
      portal: 0,
      Form: {
        title: [],
      },
    };
  },
  validations() {
    return {
      clientType: this.clientType.map(() => {
        return {
          title: { required },
        };
      }),
      // manual_code: { required },
    };
  },

  methods: {
    setCarCatName() {
      this.languages.forEach((language) => {
        this.clientType.push({
          locale: language,
          title: "",
        });
      });
    },

    addService() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.$route.params.id != undefined) {
          const params = new URLSearchParams();

          params.append("manual_code", this.manual_code);

          if (this.portal == 0) {
            params.append("is_portal", this.portal);
          }

          // this.Form.title.forEach((ele) => {
          //   params.append(`title_${ele.locale}`, ele.title);
          // });

          this.clientType.forEach((car) => {
            params.append(`title_${car.locale}`, car.title);
          });

          axios
            .put(`/client_types/${this.$route.params.id}`, params)
            .then(() => {
              Swal.fire({
                title: "",
                text: this.$t("save_successfuly"),
                icon: "success",
              });
              this.$router.push("/clientType");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          const formData = new FormData();
          formData.append("manual_code", this.manual_code);
          formData.append("is_portal", this.portal);

          // this.Form.title.forEach((ele) => {
          //   formData.append(`title_${ele.locale}`, ele.title);
          // });

          this.clientType.forEach((car) => {
            formData.append(`title_${car.locale}`, car.title);
          });

          axios
            .post(`/client_types`, formData)
            .then(() => {
              Swal.fire({
                title: "",
                text: this.$t("save_successfuly"),
                icon: "success",
              });
              this.$router.push("/clientType");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
  },

  mounted() {
    if (this.$route.params.id != undefined) {
      axios.get(`/client_types/${this.$route.params.id}`).then((res) => {
        this.clientType = res.data?.data?.titles;
        // console.log(res.data.data);
        // console.log(this.clientType, "ww");
        this.clientType?.map((car) => {
          return {
            locale: car.locale,
            title: car.title,
          };
        });
        this.manual_code = res.data.data.manual_code;
        this.loading = false;
        // console.log(this.Form.title);
      });
    }
    this.setCarCatName();
  },
  components: {
    skeltonTitles,
    TabView,
    TabPanel,
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}

.btn-stratigy {
  background-color: #0a909b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}

input.form-control {
  padding: 0.8rem !important;
}
.fa-caret-left {
  color: #0a909b;
}
.info {
  font-size: 0.7rem;
}
</style>
