<template>
  <section class="card_style" style="min-height: 100vh">
    <h4 class="mb-2">{{ $t("email_settings") }}</h4>
    <div class="main_body_style">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-12">
          <div class="form-group">
            <label>{{ $t("portal_email_sender") }}</label>
            <input
              type="email"
              class="form-control"
              :placeholder="$t('portal_email_sender')"
              v-model="Form.portal_email_sender"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="form-group">
            <label>{{ $t("portal_email_receiver") }}</label>
            <input
              type="email"
              class="form-control"
              :placeholder="$t('portal_email_receiver')"
              v-model="Form.portal_email_receiver"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="form-group">
            <label>{{ $t("password") }}</label>
            <input
              type="password"
              class="form-control"
              :placeholder="$t('password')"
              v-model="Form.password"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="main_footer_style">
      <button
        :disabled="
          Form.password == '' ||
          Form.portal_email_sender == '' ||
          Form.portal_email_receiver == ''
        "
        type="button"
        class="btn PrimaryButton"
        @click="addEmail()"
      >
        {{ $t("save") }}
      </button>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      data: JSON.parse(localStorage.getItem("user")),
      Form: {
        password: "",
        portal_email_receiver: "",
        portal_email_sender: "",
      },
    };
  },
  methods: {
    async addEmail() {
      const formData = new FormData();
      formData.append("portal_email_receiver", this.Form.portal_email_receiver);
      formData.append("portal_email_sender", this.Form.portal_email_sender);
      formData.append("portal_email_sender_password", this.Form.password);

      try {
        const res = await axios.post(
          `/setMasterOrganizationPortalEmails`,
          formData
        );

        // Display success message using SweetAlert
        Swal.fire({
          title: this.$t("success"),
          text: res.data.message,
          icon: "success",
        });

        // Update localStorage with new values
        const updatedData = {
          ...this.data, // Keep existing data
          portal_email_sender_password: this.Form.password,
          portal_email_sender: this.Form.portal_email_sender,
          portal_email_receiver: this.Form.portal_email_receiver,
        };
        localStorage.setItem("user", JSON.stringify(updatedData));
      } catch (error) {
        Swal.fire({
          title: this.$t("error"),
          text:
            error.response?.data?.message || this.$t("something_went_wrong"),
          icon: "error",
        });
      }
    },
  },
  mounted() {
    this.Form.password = this.data.portal_email_sender_password;
    this.Form.portal_email_sender = this.data.portal_email_sender;
    this.Form.portal_email_receiver = this.data.portal_email_receiver;
  },
};
</script>

<style></style>
