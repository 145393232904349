<template>
  <section class="form_organization">
    <!-- skelton -->
    <div class="row" v-if="loading && $route.params.id">
      <div class="col-12">
        <Skeleton width="25%" class="mb-3"></Skeleton>
      </div>

      <div class="col-12 my-3">
        <Skeleton width="20%" class="mb-2"></Skeleton>
        <Skeleton width="80%" height="2rem" class="my-3"></Skeleton>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 my-3" v-for="i in 15" :key="i">
        <Skeleton width="20%" class="mb-2"></Skeleton>
        <Skeleton width="80%" height="2rem" class="my-3"></Skeleton>
      </div>
    </div>
    <!-- skelton -->
    <form @submit.prevent="submitOrganization()" v-else>
      <div class="main_header">
        <h4>{{ $t("edit_company") }}</h4>
      </div>
      <div class="row">
        <!-- start input group -->
        <div class="col-12">
          <label>{{ $t("company logo") }}</label>
          <div class="image_upload">
            <label for="imageEntry" class="upload_img" v-if="imageEntry == ''">
              <img :src="image ? image : defaultImage" alt="user" />
            </label>
            <img
              :src="imageEntry"
              v-else-if="imageEntry"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntry"
              class="form-control"
              @change="onFileChange"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("company_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('company_name')"
              v-model="name"
              required
            />
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("phone") }}</label>
            <input
              type="tel"
              class="form-control xyz"
              :placeholder="$t('enter_phone')"
              v-model="phone"
              required
            />
            <span class="icon_form">
              <i class="fas fa-phone"></i>
            </span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("email") }}</label>
            <input
              type="email"
              class="form-control xyz"
              :placeholder="$t('enter_email')"
              v-model="email"
              required
            />
            <span class="icon_form">
              <i class="fa-regular fa-envelope"></i>
            </span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("country") }}</label>
            <Multiselect
              v-model="countryChoose"
              :options="countries"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchCityFromCountry"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("city") }}</label>
            <Multiselect
              v-model="cityChoose"
              :options="cities"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('enter_city')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->

        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("Allowed hotel cities") }}</label>

            <Multiselect
              v-model="multiCity"
              :options="multiCities"
              :clear-on-select="true"
              label="title"
              track-by="id"
              :placeholder="$t('Allowed hotel cities')"
              select-label=""
              :multiple="true"
              :hide-selected="true"
              @search-change="search_city"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label" for="tax_number">
              {{ $t("tax_number") }}</label
            >
            <input
              type="number"
              class="form-control"
              id="tax_number"
              :placeholder="$t('tax_number')"
              v-model="tax_number"
            />
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label" for="disntaion_number">
              {{ $t("commercial_registration_number") }}</label
            >
            <input
              type="number"
              class="form-control"
              id="disntaion_number"
              :placeholder="$t('commercial_registration_number')"
              v-model="commercial_registration_number"
            />
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label" for="lat"> lat </label>
            <input
              type="number"
              class="form-control"
              id="lat"
              placeholder="lat"
              v-model="lat"
            />
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label" for="long"> long </label>
            <input
              type="number"
              class="form-control"
              id="long"
              placeholder="long"
              v-model="long"
            />
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("adress") }}</label>
            <textarea
              class="form-control"
              :placeholder="$t('enter_adress')"
              v-model="message"
            ></textarea>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("link") }}</label>
            <input type="url" v-model="website_link" class="form-control" />
          </div>
        </div>

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("Company Business") }}</label>
            <Multiselect
              v-model="company_service_ids"
              :options="Business"
              label="service_name"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('Company Business')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("apps") }}</label>
            <Multiselect
              v-model="company_app_ids"
              :options="Apps"
              label="app_name"
              trck-by="id"
              :clear-on-select="true"
              :placeholder="$t('apps')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-2 mt-3">
            <div class="input-group">
              <label class="form-label"> {{ $t("only_portal") }}</label>
              <div class="">
                <Checkbox v-model="checked" :binary="true" />
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-2 mt-3">
            <div class="input-group">
              <label class="form-label"> {{ $t("offline_organization_request") }}</label>
              <div class="">
                <Checkbox v-model="website_hotel_request_type" :binary="true" />
              </div>
            </div>
          </div>


          <div class="col-12 col-lg-2 mt-3">
            <div class="input-group">
              <label class="form-label"> {{ $t("portal Contract") }}</label>
              <div class="">
                <Checkbox v-model="checked_portal_contract" :binary="true" />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2 mt-3">
            <div class="input-group">
              <label class="form-label"> {{ $t("deal as hotel") }}</label>
              <div class="">
                <Checkbox v-model="checked_hotel" :binary="true" />
              </div>
            </div>
          </div>
        </div>
        <!-- finish input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("edit") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Checkbox from "primevue/checkbox";
import Skeleton from "primevue/skeleton";

export default {
  name: "organization-form",
  components: {
    Multiselect,
    Skeleton,
    Checkbox,
  },
  data() {
    return {
      checked: false,
      checked_portal_contract: false,
      checked_hotel: false,
      website_hotel_request_type: false,
      name: "",
      phone: "",
      loading: true,
      email: "",
      countryChoose: "",
      cityChoose: "",
      message: "",
      website_link: "",
      lat: null,
      long: null,
      countries: [],
      cities: [],
      multiCity: "",
      multiCities: [],
      wallet: "",
      tax_number: "",
      commercial_registration_number: "",
      image: null,
      imageEntry: "",
      defaultImage: require("@/assets/media/image/user.png"),
      Business: [
        {
          id: 1,
          service_name: this.$t("The Hotels"),
        },
        {
          id: 2,
          service_name: this.$t("flight"),
        },
        {
          id: 3,
          service_name: this.$t("transportation"),
        },
        {
          id: 4,
          service_name: this.$t("affiliate"),
        },
        {
          id: 5,
          service_name: this.$t("Umrah_packages"),
        },
        {
          id: 9,
          service_name: this.$t("services"),
        },
        {
          id: 10,
          service_name: this.$t("travel_package"),
        },
      ],
      Apps: [
        {
          id: 1,
          app_name: this.$t("SYSTEM"),
        },
        {
          id: 2,
          app_name: this.$t("WEBSITE"),
        },
        {
          id: 3,
          app_name: this.$t("APP"),
        },
      ],
      company_business: "",
      company_service_ids: [],
      company_app_ids: [],
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        // console.log(this.services);
      });
    },
    //fetch city with country
    FetchCityFromCountry(value) {
      this.cities = [];
      this.cityChoose = "";
      let basic_country_ids = {
        country_id: [value.id],
      };
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
        });
    },

    search_city(search) {
      if (search.length >= 2) {
        axios.post("/search_city", { title: search }).then(({ data }) => {
          this.multiCities = data.data.data;
        });
      }
    },
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    fetchCompanyData() {
      let id = this.$route.params.id;
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/organizations/" + id).then(({ data }) => {
        this.checked = data.data.is_portal == 1 ? true : false;
        this.checked_hotel = data.data.is_hotel == 1 ? true : false;
        this.checked_portal_contract =
          data.data.is_portal_contract == 1 ? true : false;

        this.name = data.data.name;
        this.phone = data.data.phone;
        this.email = data.data.email;
        this.message = data.data.address;
        this.countryChoose = data.data.country;
        this.cityChoose = data.data.city;
        this.multiCity = data.data.cities;
        this.lat = data.data.lat;
        this.long = data.data.long;
        this.website_link = data.data.website_link;
        this.tax_number = data.data.tax_number;
        this.commercial_registration_number =
          data.data.commercial_registration_number;
        this.image = data.data.image;
        this.loading = false;
        this.company_service_ids = data.data.services;
        this.company_app_ids = data.data.apps;
        this.website_hotel_request_type = data.data.website_hotel_request_type == 1 ? true : false;
        // console.log(data.data.apps, "this.company_app_ids");

        // localStorage.setItem("countryValue", this.countryChoose.id);
      });
    },
    async submitOrganization() {
      let id = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("name", this.name);
      params.append("phone", this.phone);
      params.append("email", this.email);
      params.append("address", this.message);
      params.append("website_link", this.website_link);
      params.append("country_id", this.countryChoose?.id);
      params.append("city_id", this.cityChoose?.id);
      params.append("lat", this.lat);
      params.append("long", this.long);
      params.append("is_portal", this.checked ? 1 : 0);
      params.append("is_hotel", this.checked_hotel ? 1 : 0);
      params.append("is_portal_contract", this.checked_portal_contract ? 1 : 0);
      params.append("website_hotel_request_type", this.website_hotel_request_type ? 1 : 2);
      params.append(
        "commercial_registration_number",
        this.commercial_registration_number
      );
      params.append("tax_number", this.tax_number);
      if (this.fileData instanceof File) {
        params.append("image", this.fileData);
      }
      this.company_service_ids?.forEach((company_service, index) => {
        params.append(`service_ids[${index}]`, company_service.id);
      });

      this.company_app_ids?.forEach((company_app, index) => {
        params.append(`app_ids[${index}]`, company_app.id);
      });

      this.multiCity.forEach((city, index) => {
        params.append(`organization_city_ids[${index}]`, city.id);
      });
      axios
        .put(`/organizations/${id}`, params)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/organization-tree");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.fetch_country();
    this.fetchCompanyData();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./organization.scss";
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
</style>
