<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <div class="d-flex align-items-center gap-3 mb-2">
        <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
        <h4 class="mb-0">
          {{ $t("Contact Us") }}
        </h4>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <input
          type="text"
          class="form-control mb-2"
          v-model="messageText"
          :placeholder="$t('search...')"
          @keyup="fetchContacts((page = 1))"
        />
      </div>
    </div>

    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("full name") }}</th>
            <th>{{ $t("email") }}</th>
            <th>{{ $t("message_title") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="contact in 10" :key="contact">
            <td v-for="contact in 4" :key="contact">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(contact, index) in contacts.data" :key="index">
            <td>{{ contact.id }}</td>
            <td>{{ contact.full_name }}</td>
            <td>{{ contact.email }}</td>
            <td>{{ contact.message }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="contacts"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchContacts"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";
export default {
  name: "contact-website",
  data() {
    return {
      contacts: [],
      messageText: "",
      loading: true,
    };
  },
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  methods: {
    async fetchContacts(page = 1) {
      const formData = new FormData();
      formData.append("word", this.messageText);
      if(this.$route.params.type === "portal") {
        formData.append("is_portal", 1);
      }
      await axios
        .post(`/fetch_contact_us?page=${page}`, formData)
        .then(({ data }) => {
          this.contacts = data.data;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchContacts();
  },
};
</script>

<style scoped>
table tbody tr td img {
  width: 100px;
  height: 100px;
}
</style>
