<template>
  <div class="card_style day_stock">
    <div class="row">
      <div class="col-12 d-flex align-items-center gap-3 mb-2">
        <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
        <h4 class="mb-0">
          {{ $t("Day Stock") }}
        </h4>
      </div>
      <div class="col-12 d-flex align-items-center gap-3">
        <div class="form-group d-flex align-items-center gap-2">
          <input
            id="all"
            v-model="all"
            class="form-check-input mt-0"
            name="flexRadioDefault"
            type="radio"
            value="all"
          />
          <label class="form-label mb-0">{{ $t("all") }}</label>
        </div>
        <div class="form-group d-flex align-items-center gap-2">
          <input
            id="weekday"
            v-model="all"
            class="form-check-input mt-0"
            name="flexRadioDefault"
            type="radio"
            value="weekday"
          />
          <label class="form-label mb-0">{{ $t("weekDay") }}</label>
        </div>
        <div class="form-group d-flex align-items-center gap-2">
          <input
            id="weekend"
            v-model="all"
            class="form-check-input mt-0"
            name="flexRadioDefault"
            type="radio"
            value="weekend"
          />
          <label class="form-label mb-0">{{ $t("Weekend") }}</label>
        </div>
        <div class="form-group d-flex align-items-center gap-2">
          <input
            id="weekend"
            v-model="all"
            class="form-check-input mt-0"
            name="flexRadioDefault"
            type="radio"
            value="specific"
          />
          <label class="form-label mb-0">{{ $t("specific") }}</label>
        </div>

        <div v-if="all == 'specific'" class="form-group">
          <label class="form-label">{{ $t("from") }}</label>
          <datepicker
            v-model="fromDate"
            :disabled-dates="{
              to: new Date(),
            }"
            :placeholder="$t('From')"
            name="durationFrom"
            @input="
              countMethod();
              extraMethod();
            "
          ></datepicker>
        </div>
        <div v-if="all == 'specific'" class="form-group">
          <label class="form-label">{{ $t("to") }}</label>
          <datepicker
            v-model="toDate"
            :disabled-dates="{
              to: new Date(fromDate),
            }"
            :placeholder="$t('To')"
            name="durationTo"
            @input="
              countMethod();
              extraMethod();
            "
          ></datepicker>
        </div>

        <div class="form-group d-flex align-items-center gap-2">
          <label class="form-label mb-0">{{ $t("count") }}</label>
          <input
            v-model="count"
            class="form-control mt-0"
            type="number"
            @input="countMethod"
          />
        </div>
        <div class="form-group d-flex align-items-center gap-2">
          <label class="form-label mb-0 text-nowrap">{{
            $t("Over Booking")
          }}</label>
          <input
            v-model="extra"
            class="form-control mt-0"
            type="number"
            @input="extraMethod"
          />
        </div>
        <div class="form-group d-flex align-items-center gap-2">
          <button
            v-if="days"
            v-tooltip="$t('excel')"
            class="btn SecondButton"
            @click="exportToExcel"
          >
            <i class="fa-solid fa-file-excel"></i>
          </button>
        </div>
        <div class="form-group d-flex align-items-center gap-2">
          <router-link
            :to="`/days-stock/history/${this.$route.params.id}`"
            class="btn PrimaryButton"
            style="margin-inline-start: unset"
          >
            <i class="fa-solid fa-history"></i>
          </router-link>
        </div>
        <div class="form-group d-flex align-items-center gap-2">
          <button class="btn SecondButton" @click="updateData">
            {{ $t("add") }}
          </button>
        </div>
      </div>
      <p class="small-font-size text-info">
        -1 = {{ $t("infinite_number_of_rooms") }}
      </p>
      <hr />
      <!-- stock -->
      <div class="table-responsive">
        <table class="table table-bordered mb-0 overflow-x-auto">
          <thead>
            <tr v-if="stockLoader">
              <th scope="col">{{ $t("date") }}</th>
              <th v-for="day in 20" :key="day" scope="col">
                <Skeleton class="mb-2" height="2rem" width="90%"></Skeleton>
              </th>
            </tr>
            <tr v-else>
              <th scope="col">{{ $t("date") }}</th>
              <th
                v-for="(day, item) in days"
                :key="item"
                :style="{
                  'background-color': day.is_weekend ? '#0a909b' : '#535353',
                }"
                scope="col"
              >
                <!-- <div class="quantity">
                        <button
                          class="btn minus"
                          type="button"
                          @click="minusQuantity(index)"
                        >
                          <i class="fa-solid fa-minus"></i>
                        </button>
                        <span class="number">{{ room.count }}</span>
                        <button
                          class="btn add"
                          type="button"
                          @click="addQuantity(index)"
                        >
                          <i class="fa-solid fa-plus"></i>
                        </button>
                      </div> -->
                <div
                  class="d-flex align-items-baseline justify-content-between"
                >
                  <div class="d-flex flex-column">
                    <p
                      :class="[
                        'mb-0',
                        'day_head',
                        day.is_weekend ? 'text-white' : 'text-white',
                      ]"
                    >
                      {{ formatDate(day.day) }}
                    </p>

                    <p
                      :class="[day.is_weekend ? 'text-white' : 'text-white']"
                      class="hijri mb-0"
                    >
                      {{ updateHijriDate(new Date(day.day)) }}
                    </p>
                    <p
                      :class="[
                        'mb-0',
                        'day_head',
                        day.is_weekend ? 'text-white' : 'text-white',
                      ]"
                    >
                      {{ getWeekday(new Date(day.day)) }}
                    </p>
                  </div>
                  <i
                    v-if="day.has_edited == '1'"
                    class="fa-solid fa-circle fa-2xs text-white"
                  ></i>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="stockLoader">
              <th scope="col">{{ $t("count") }}</th>
              <th v-for="day in 20" :key="day" scope="col">
                <Skeleton class="mb-2" height="2rem" width="90%"></Skeleton>
              </th>
            </tr>
            <tr v-else>
              <th scope="row">{{ $t("count") }}</th>
              <td v-for="(day, item) in days" :key="item">
                <input
                  v-model="day.stock"
                  :disabled="disabledInputs(day)"
                  class="form-control"
                  min="0"
                  type="number"
                />
              </td>
            </tr>
            <tr v-if="stockLoader">
              <th scope="col">{{ $t("Over Booking") }}</th>
              <th v-for="day in 20" :key="day" scope="col">
                <Skeleton class="mb-2" height="2rem" width="90%"></Skeleton>
              </th>
            </tr>
            <tr v-else>
              <th scope="row">{{ $t("Over Booking") }}</th>
              <td v-for="(day, item) in days" :key="item">
                <input
                  v-model="day.minus_count"
                  :disabled="disabledInputs(day)"
                  class="form-control"
                  min="-1"
                  type="number"
                />
              </td>
            </tr>
            <tr v-if="stockLoader">
              <th scope="col">{{ $t("available") }}</th>
              <th v-for="day in 20" :key="day" scope="col">
                <Skeleton class="mb-2" height="2rem" width="90%"></Skeleton>
              </th>
            </tr>
            <tr v-else>
              <td>{{ $t("available") }}</td>
              <td v-for="(day, item) in days" :key="item">
                <input
                  v-model="day.available_stock"
                  class="form-control"
                  disabled
                  min="0"
                  type="number"
                />
              </td>
            </tr>
            <tr v-if="stockLoader">
              <th scope="col">{{ $t("booked") }}</th>
              <th v-for="day in 20" :key="day" scope="col">
                <Skeleton class="mb-2" height="2rem" width="90%"></Skeleton>
              </th>
            </tr>
            <tr v-else>
              <td>{{ $t("booked") }}</td>
              <td v-for="(day, item) in days" :key="item">
                <input
                  v-model="day.booked_room"
                  v-tooltip.top="
                    '   Total Confirmed: ' +
                    day.total_booked_confirmed_room +
                    '  ' +
                    '     Total Tentative: ' +
                    day.total_booked_tentative_room
                  "
                  class="form-control"
                  disabled
                  min="0"
                  type="number"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
      <!-- <div class="row">
        <div class="col-6 d-flex flex-column">
          <h4>{{ $t("filter_all_days") }}</h4>
          <div class="d-flex align-items-center gap-3">
            <div class="form-group d-flex align-items-center gap-2">
              <input
                class="form-check-input mt-0"
                type="radio"
                name="flexRadioDefault"
                id="all"
                value="all"
                v-model="ratecode.filterDays"
              />
              <label class="form-label mb-0">{{ $t("all") }}</label>
            </div>
            <div class="form-group d-flex align-items-center gap-2">
              <input
                class="form-check-input mt-0"
                type="radio"
                name="flexRadioDefault"
                id="specific"
                value="specific"
                v-model="ratecode.filterDays"
              />
              <label class="form-label mb-0">{{ $t("specific") }}</label>
            </div>
            <div class="form-group d-flex align-items-center gap-2">
              <input
                class="form-check-input mt-0"
                type="radio"
                name="flexRadioDefault"
                id="weekday"
                value="weekday"
                v-model="ratecode.filterDays"
              />
              <label class="form-label mb-0">{{ $t("weekday") }}</label>
            </div>
            <div class="form-group d-flex align-items-center gap-2">
              <input
                class="form-check-input mt-0"
                type="radio"
                name="flexRadioDefault"
                id="weekend"
                value="weekend"
                v-model="ratecode.filterDays"
              />
              <label class="form-label mb-0">{{ $t("weekend") }}</label>
            </div>
          </div>
          <div
            v-if="ratecode.filterDays === 'all'"
            class="d-flex gap-3 flex-wrap"
          >
            <div class="form-group">
              <label class="form-label">{{ $t("Cost - Weekday") }} </label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.cost_weekday"
              />
            </div>
            <div class="form-group">
              <label class="form-label">{{ $t("Cost - Weekend") }} </label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.cost_weekend"
              />
            </div>
            <div class="form-group">
              <label class="form-label">{{
                $t("B2B - Weekday (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2b_weekday"
              />
            </div>
            <div class="form-group">
              <label class="form-label">{{
                $t("B2B - Weekend (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2b_weekend"
              />
            </div>
            <div class="form-group">
              <label class="form-label">{{
                $t("B2C - Weekday (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2c_weekday"
              />
            </div>
            <div class="form-group">
              <label class="form-label">{{
                $t("B2C - Weekend (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2c_weekend"
              />
            </div>
          </div>
          <div v-else-if="ratecode.filterDays === 'specific'" class="row">
            <div class="form-group col-4">
              <label class="form-label">{{ $t("from") }}</label>
              <datepicker
                v-model="ratecode.fromDate"
                name="durationFrom"
                @input="filterDaysMethod(ratecode)"
                :placeholder="$t('From')"
                :disabled-dates="{
                  to: new Date(fromDateSeasons),
                  from: new Date(toDateSeasons),
                }"
              ></datepicker>
            </div>
            <div class="form-group col-4">
              <label class="form-label">{{ $t("to") }}</label>
              <datepicker
                v-model="ratecode.toDate"
                name="durationTo"
                @input="filterDaysMethod(ratecode)"
                :placeholder="$t('To')"
                :disabled-dates="{
                  to: new Date(fromDateSeasons),
                  from: new Date(toDateSeasons),
                }"
              ></datepicker>
            </div>
            <div class="form-group col-4">
              <label class="form-label">{{ $t("Cost - Weekday") }} </label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.cost_weekday"
              />
            </div>
            <div class="form-group col-4">
              <label class="form-label">{{ $t("Cost - Weekdend") }} </label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.cost_weekend"
              />
            </div>

            <div class="form-group col-4">
              <label class="form-label">{{
                $t("B2B - Weekday (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2b_weekday"
              />
            </div>
            <div class="form-group col-4">
              <label class="form-label">{{
                $t("B2B - Weekend (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2b_weekend"
              />
            </div>
            <div class="form-group col-4">
              <label class="form-label">{{
                $t("B2C - Weekday (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2c_weekday"
              />
            </div>
            <div class="form-group col-4">
              <label class="form-label">{{
                $t("B2C - Weekend (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2c_weekend"
              />
            </div>
          </div>
          <div class="row" v-else-if="ratecode.filterDays === 'weekend'">
            <div class="form-group col-4">
              <label class="form-label">{{ $t("Cost - Weekdend") }} </label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.cost_weekend"
              />
            </div>
            <div class="form-group col-4">
              <label class="form-label">{{
                $t("B2B - Weekend (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2b_weekend"
              />
            </div>
            <div class="form-group col-4">
              <label class="form-label">{{
                $t("B2C - Weekend (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2c_weekend"
              />
            </div>
          </div>
          <div class="row" v-else-if="ratecode.filterDays === 'weekday'">
            <div class="form-group col-4">
              <label class="form-label">{{ $t("Cost - Weekday") }} </label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.cost_weekday"
              />
            </div>
            <div class="form-group col-4">
              <label class="form-label">{{
                $t("B2B - Weekday (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2b_weekday"
              />
            </div>
            <div class="form-group col-4">
              <label class="form-label">{{
                $t("B2C - Weekday (Selling)")
              }}</label>
              <input
                type="number"
                class="form-control"
                @input="filterDaysMethod(ratecode)"
                v-model="ratecode.b2c_weekday"
              />
            </div>
          </div>
        </div>
      </div> -->
      <!-- Rooms  -->
      <div v-if="rateCodeLoader" class="border rounded p-3">
        <div
          v-for="(room, index) in 4"
          :key="index"
          :class="[
            'd-flex',
            'align-items-center',
            'gap-3',
            'p-2',
            'justify-content-between',
            'mt-2',
            index == 3 ? '' : 'border-bottom',
          ]"
        >
          <Skeleton class="mb-2" height=".5rem" width="10%"></Skeleton>
          <i class="fa-solid fa-chevron-down"></i>
        </div>
      </div>
      <Accordion v-else :activeIndex="0" class="border rounded" multiple>
        <AccordionTab
          v-for="(room, index) in rateCode"
          :key="index"
          :header="room.name"
          clas="border border border-info-subtle"
        >
          <div class="details_room mb-3 text-black border rounded p-3 mt-3">
            <div class="col-12 d-flex flex-column border-1">
              <h4>{{ $t("Filter Days For All Rate Code") }}-{{ room.name }}</h4>
              <div class="d-flex align-items-center gap-3">
                <div class="form-group d-flex align-items-center gap-2">
                  <input
                    id="all"
                    v-model="room.filterDays"
                    class="form-check-input mt-0"
                    name="flexRadioDefault"
                    type="radio"
                    value="all"
                  />
                  <label class="form-label mb-0">{{ $t("all") }}</label>
                </div>
                <div class="form-group d-flex align-items-center gap-2">
                  <input
                    id="specific"
                    v-model="room.filterDays"
                    class="form-check-input mt-0"
                    name="flexRadioDefault"
                    type="radio"
                    value="specific"
                  />
                  <label class="form-label mb-0">{{ $t("specific") }}</label>
                </div>
                <div class="form-group d-flex align-items-center gap-2">
                  <input
                    id="weekday"
                    v-model="room.filterDays"
                    class="form-check-input mt-0"
                    name="flexRadioDefault"
                    type="radio"
                    value="weekday"
                  />
                  <label class="form-label mb-0">{{ $t("weekday") }}</label>
                </div>
                <div class="form-group d-flex align-items-center gap-2">
                  <input
                    id="weekend"
                    v-model="room.filterDays"
                    class="form-check-input mt-0"
                    name="flexRadioDefault"
                    type="radio"
                    value="weekend"
                  />
                  <label class="form-label mb-0">{{ $t("weekend") }}</label>
                </div>
              </div>
              <div
                v-if="room.filterDays === 'all'"
                class="d-flex gap-3 flex-wrap"
              >
                <div class="form-group">
                  <label class="form-label">{{ $t("Cost - Weekday") }} </label>
                  <input
                    v-model="room.cost_weekday"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">{{ $t("Cost - Weekend") }} </label>
                  <input
                    v-model="room.cost_weekend"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">{{
                    $t("B2B - Weekday (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2b_weekday"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">{{
                    $t("B2B - Weekend (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2b_weekend"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">{{
                    $t("B2C - Weekday (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2c_weekday"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">{{
                    $t("B2C - Weekend (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2c_weekend"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
              </div>
              <div v-else-if="room.filterDays === 'specific'" class="row">
                <div class="form-group col-3">
                  <label class="form-label">{{ $t("from") }}</label>
                  <datepicker
                    v-model="room.fromDate"
                    :disabled-dates="{
                      to: new Date(),
                    }"
                    :placeholder="$t('From')"
                    name="durationFrom"
                    @input="filterDaysMethod(room, 'all')"
                  ></datepicker>
                </div>
                <div class="form-group col-3">
                  <label class="form-label">{{ $t("to") }}</label>
                  <datepicker
                    v-model="room.toDate"
                    :disabled-dates="{
                      to: room.fromDate,
                    }"
                    :placeholder="$t('To')"
                    name="durationTo"
                    @input="filterDaysMethod(room, 'all')"
                  ></datepicker>
                </div>
                <div class="form-group col-3">
                  <label class="form-label">{{ $t("Cost - Weekday") }} </label>
                  <input
                    v-model="room.cost_weekday"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group col-3">
                  <label class="form-label">{{ $t("Cost - Weekdend") }} </label>
                  <input
                    v-model="room.cost_weekend"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>

                <div class="form-group col-3">
                  <label class="form-label">{{
                    $t("B2B - Weekday (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2b_weekday"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group col-3">
                  <label class="form-label">{{
                    $t("B2B - Weekend (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2b_weekend"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group col-3">
                  <label class="form-label">{{
                    $t("B2C - Weekday (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2c_weekday"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group col-3">
                  <label class="form-label">{{
                    $t("B2C - Weekend (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2c_weekend"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
              </div>
              <div v-else-if="room.filterDays === 'weekend'" class="row">
                <div class="form-group col-4">
                  <label class="form-label">{{ $t("Cost - Weekdend") }} </label>
                  <input
                    v-model="room.cost_weekend"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2B - Weekend (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2b_weekend"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2C - Weekend (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2c_weekend"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
              </div>
              <div v-else-if="room.filterDays === 'weekday'" class="row">
                <div class="form-group col-4">
                  <label class="form-label">{{ $t("Cost - Weekday") }} </label>
                  <input
                    v-model="room.cost_weekday"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2B - Weekday (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2b_weekday"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
                <div class="form-group col-4">
                  <label class="form-label">{{
                    $t("B2C - Weekday (Selling)")
                  }}</label>
                  <input
                    v-model="room.b2c_weekday"
                    class="form-control"
                    type="number"
                    @input="filterDaysMethod(room, 'all')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(ratecode, index) in room.rate_codes"
            :key="index"
            class="details_price text-black border rounded p-3 mt-3"
          >
            <div class="row">
              <div class="col-12 d-flex flex-column">
                <h4>
                  {{ $t("Rate code") }} -
                  {{ ratecode.name ? ratecode.name : ratecode.title }}
                </h4>
                <div class="d-flex align-items-center gap-3">
                  <div class="form-group d-flex align-items-center gap-2">
                    <input
                      id="all"
                      v-model="ratecode.filterDays"
                      class="form-check-input mt-0"
                      name="flexRadioDefault"
                      type="radio"
                      value="all"
                    />
                    <label class="form-label mb-0">{{ $t("all") }}</label>
                  </div>
                  <div class="form-group d-flex align-items-center gap-2">
                    <input
                      id="specific"
                      v-model="ratecode.filterDays"
                      class="form-check-input mt-0"
                      name="flexRadioDefault"
                      type="radio"
                      value="specific"
                    />
                    <label class="form-label mb-0">{{ $t("specific") }}</label>
                  </div>
                  <div class="form-group d-flex align-items-center gap-2">
                    <input
                      id="weekday"
                      v-model="ratecode.filterDays"
                      class="form-check-input mt-0"
                      name="flexRadioDefault"
                      type="radio"
                      value="weekday"
                    />
                    <label class="form-label mb-0">{{ $t("weekday") }}</label>
                  </div>
                  <div class="form-group d-flex align-items-center gap-2">
                    <input
                      id="weekend"
                      v-model="ratecode.filterDays"
                      class="form-check-input mt-0"
                      name="flexRadioDefault"
                      type="radio"
                      value="weekend"
                    />
                    <label class="form-label mb-0">{{ $t("weekend") }}</label>
                  </div>
                </div>
                <div
                  v-if="ratecode.filterDays === 'all'"
                  class="d-flex gap-3 flex-wrap"
                >
                  <div class="form-group">
                    <label class="form-label"
                      >{{ $t("Cost - Weekday") }}
                    </label>
                    <input
                      v-model="ratecode.cost_weekday"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label"
                      >{{ $t("Cost - Weekend") }}
                    </label>
                    <input
                      v-model="ratecode.cost_weekend"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{
                      $t("B2B - Weekday (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2b_weekday"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{
                      $t("B2B - Weekend (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2b_weekend"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{
                      $t("B2C - Weekday (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2c_weekday"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{
                      $t("B2C - Weekend (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2c_weekend"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                </div>
                <div v-else-if="ratecode.filterDays === 'specific'" class="row">
                  <div class="form-group col-3">
                    <label class="form-label">{{ $t("from") }}</label>
                    <datepicker
                      v-model="ratecode.fromDate"
                      :disabled-dates="{
                        to: new Date(),
                      }"
                      :placeholder="$t('From')"
                      name="durationFrom"
                      @input="filterDaysMethod(ratecode)"
                    ></datepicker>
                  </div>
                  <div class="form-group col-3">
                    <label class="form-label">{{ $t("to") }}</label>
                    <datepicker
                      v-model="ratecode.toDate"
                      :disabled-dates="{
                        to: ratecode.fromDate,
                      }"
                      :placeholder="$t('To')"
                      name="durationTo"
                      @input="filterDaysMethod(ratecode)"
                    ></datepicker>
                  </div>
                  <div class="form-group col-3">
                    <label class="form-label"
                      >{{ $t("Cost - Weekday") }}
                    </label>
                    <input
                      v-model="ratecode.cost_weekday"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group col-3">
                    <label class="form-label"
                      >{{ $t("Cost - Weekdend") }}
                    </label>
                    <input
                      v-model="ratecode.cost_weekend"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>

                  <div class="form-group col-3">
                    <label class="form-label">{{
                      $t("B2B - Weekday (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2b_weekday"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group col-3">
                    <label class="form-label">{{
                      $t("B2B - Weekend (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2b_weekend"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group col-3">
                    <label class="form-label">{{
                      $t("B2C - Weekday (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2c_weekday"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group col-3">
                    <label class="form-label">{{
                      $t("B2C - Weekend (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2c_weekend"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                </div>
                <div v-else-if="ratecode.filterDays === 'weekend'" class="row">
                  <div class="form-group col-4">
                    <label class="form-label"
                      >{{ $t("Cost - Weekdend") }}
                    </label>
                    <input
                      v-model="ratecode.cost_weekend"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group col-4">
                    <label class="form-label">{{
                      $t("B2B - Weekend (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2b_weekend"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group col-4">
                    <label class="form-label">{{
                      $t("B2C - Weekend (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2c_weekend"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                </div>
                <div v-else-if="ratecode.filterDays === 'weekday'" class="row">
                  <div class="form-group col-4">
                    <label class="form-label"
                      >{{ $t("Cost - Weekday") }}
                    </label>
                    <input
                      v-model="ratecode.cost_weekday"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group col-4">
                    <label class="form-label">{{
                      $t("B2B - Weekday (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2b_weekday"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                  <div class="form-group col-4">
                    <label class="form-label">{{
                      $t("B2C - Weekday (Selling)")
                    }}</label>
                    <input
                      v-model="ratecode.b2c_weekday"
                      class="form-control"
                      type="number"
                      @input="filterDaysMethod(ratecode)"
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-12 d-flex justify-content-end align-items-end gap-3"
              >
                <StopSellingDayStock
                  :fetchRateCodeData="ratecode"
                  :roomProp="room"
                  class="mb-2"
                />
                <router-link
                  :to="`/ratecode/history/${room.id}`"
                  class="btn PrimaryButton mb-2"
                  style="margin-inline-start: unset"
                >
                  <i class="fa-solid fa-history"></i>
                </router-link>
                <button
                  class="btn PrimaryButton mb-2"
                  style="margin-inline-start: unset"
                  @click="updateDays(ratecode, 1)"
                >
                  <i
                    v-if="ratecode.loader"
                    class="fa-solid fa-spinner fa-spin-pulse"
                  ></i>
                  <span v-else class="d-flex align-items-center gap-1"
                    ><i class="fa-solid fa-floppy-disk"></i>
                    {{ $t("update") }}</span
                  >
                </button>
                <button
                  class="btn SecondButton mb-2"
                  @click="exportToExcel(ratecode, 'days_stock')"
                >
                  <i class="fa-solid fa-file-excel"></i>
                  {{ $t("export") }}
                </button>
              </div>
            </div>
            <div class="details_room">
              <!-- <div
              class="table-responsive"
              v-if="ratecode.filterDaysArray.length > 0"
            >
              <table class="table table-bordered mb-0 overflow-x-auto">
                <thead>
                  <tr>
                    <th scope="col">{{ $t("date") }}</th>
                    <th
                      scope="col"
                      v-for="(day, item) in ratecode.filterDaysArray"
                      :key="item"
                      :style="{
                        'background-color': day.is_weekend
                          ? '#0a909b'
                          : '#535353',
                      }"
                    >
                      <div
                        class="d-flex align-items-baseline justify-content-between"
                      >
                        <div class="d-flex flex-column">
                          <p
                            :class="[
                              'mb-0',
                              'day_head',
                              day.is_weekend ? 'text-black' : 'text-white',
                            ]"
                          >
                            {{ formatDate(day.date) }}
                          </p>

                          <p
                            class="hijri mb-0"
                            :class="[
                              day.is_weekend ? 'text-black' : 'text-white',
                            ]"
                          >
                            {{ updateFromHijriDate(new Date(day.date)) }}
                          </p>
                          <p
                            :class="[
                              'mb-0',
                              'day_head',
                              day.is_weekend ? 'text-black' : 'text-white',
                            ]"
                          >
                            {{ getWeekday(new Date(day.date)) }}
                          </p>
                        </div>
                        <i
                          class="fa-solid fa-circle fa-2xs text-white"
                          v-if="day.has_edited == '1'"
                        ></i>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{{ $t("cost") }}</th>
                    <td
                      v-for="(day, item) in ratecode.filterDaysArray"
                      :key="item"
                    >
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        v-model="day.cost"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">B2B</th>
                    <td
                      v-for="(day, item) in ratecode.filterDaysArray"
                      :key="item"
                    >
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        v-model="day.b2b_price"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">B2C</th>
                    <td
                      v-for="(day, item) in ratecode.filterDaysArray"
                      :key="item"
                    >
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        v-model="day.b2c_price"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
              <div class="table-responsive">
                <table class="table table-bordered mb-0 overflow-x-auto">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t("date") }}</th>
                      <th
                        v-for="(day, item) in ratecode.days"
                        :key="item"
                        :style="{
                          'background-color': day.is_weekend
                            ? '#0a909b'
                            : '#535353',
                        }"
                        scope="col"
                      >
                        <div
                          class="d-flex align-items-baseline justify-content-between"
                        >
                          <div class="d-flex flex-column">
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              <p
                                :class="[
                                  'mb-0',
                                  'day_head',
                                  day.is_weekend ? 'text-white' : 'text-white',
                                ]"
                              >
                                {{ formatDate(day.date) }}
                              </p>
                              <i
                                v-if="day.is_stop"
                                class="fa-solid fa-lock text-white cursor-pointer"
                                @click="stopDay(day, room.id)"
                              ></i>
                              <i
                                v-else
                                class="fa-solid fa-lock-open text-white cursor-pointer"
                                @click="stopDay(day, room.id)"
                              ></i>
                            </div>

                            <p
                              :class="[
                                day.is_weekend ? 'text-white' : 'text-white',
                              ]"
                              class="hijri mb-0"
                            >
                              {{ updateHijriDate(new Date(day.date)) }}
                            </p>
                            <p
                              :class="[
                                'mb-0',
                                'day_head',
                                day.is_weekend ? 'text-white' : 'text-white',
                              ]"
                            >
                              {{ getWeekday(new Date(day.date)) }}
                            </p>
                          </div>
                          <i
                            v-if="day.has_edited == '1'"
                            class="fa-solid fa-circle fa-2xs text-white"
                          ></i>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">{{ $t("cost") }}</th>
                      <td v-for="(day, item) in ratecode.days" :key="item">
                        <input
                          v-model="day.cost"
                          :disabled="disabledInputs(day)"
                          class="form-control"
                          min="0"
                          type="number"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">B2B</th>
                      <td v-for="(day, item) in ratecode.days" :key="item">
                        <input
                          v-model="day.b2b_price"
                          :disabled="disabledInputs(day)"
                          class="form-control"
                          min="0"
                          type="number"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">B2C</th>
                      <td v-for="(day, item) in ratecode.days" :key="item">
                        <input
                          v-model="day.b2c_price"
                          :disabled="disabledInputs(day)"
                          class="form-control"
                          min="0"
                          type="number"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Datepicker from "vuejs3-datepicker";
import Skeleton from "primevue/skeleton";
import * as XLSX from "xlsx/xlsx.mjs";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import StopSellingDayStock from "@/components/hotels/contrackt/season/StopSellingDayStock.vue";

export default {
  name: "dayStock",
  components: {
    StopSellingDayStock,
    AddFavDailog,
    Accordion,
    AccordionTab,
    Datepicker,
    Skeleton,
  },
  data() {
    return {
      days: [],
      stockLoader: true,
      rateCodeLoader: true,
      toDate: null,
      fromDate: null,
      all: "",
      count: "",
      extra: "",
      rateCode: [],
    };
  },

  methods: {
    disabledInputs(day) {
      // console.log(day);
      const date = new Date(day.day === undefined ? day.date : day.day);
      return date <= new Date();
    },

    updateData() {
      const formData = new FormData();
      formData.append("contract_id", this.$route.params.id);
      this.days.forEach((day, index) => {
        formData.append(`days[${index}][stock]`, day.stock);
        formData.append(`days[${index}][minus_count]`, day.minus_count);
        formData.append(`days[${index}][day]`, day.day);
      });
      axios
        .post("/updateContractDaysStock", formData)
        .then(({ data }) => {
          Swal.fire({
            title: this.$t("success"),
            text: data.message,
            icon: "success",
          });
          this.fetchDays();
        })
        .catch((error) => {
          Swal.fire({
            title: this.$t("error"),
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    countMethod() {
      this.updateDaysStock("stock", this.count);
    },

    extraMethod() {
      this.updateDaysStock("minus_count", this.extra);
    },

    updateDaysStock(field, value) {
      const today = moment().startOf("day");

      this.days.forEach((day) => {
        const dayDate = moment(day.day, "YYYY-MM-DD");

        if (!dayDate.isAfter(today, "day")) {
          return; // Skip updating if the day is today or before
        }

        switch (this.all) {
          case "all":
            this.updateDayField(day, field, value);
            break;
          case "weekday":
            if (!day.is_weekend) {
              this.updateDayField(day, field, value);
            }
            break;
          case "weekend":
            if (day.is_weekend) {
              this.updateDayField(day, field, value);
            }
            break;
          case "specific":
            this.updateSpecificDayField(day, dayDate, field, value);
            break;
        }
      });
    },

    updateDayField(day, field, value) {
      day[field] = value;
    },

    updateSpecificDayField(day, dayDate, field, value) {
      const fromDate = moment(this.fromDate, "YYYY-MM-DD");
      const toDate = moment(this.toDate, "YYYY-MM-DD");

      if (dayDate.isBetween(fromDate, toDate, "day", "[]") && value > 0) {
        this.updateDayField(day, field, value);
      }
    },
    fetchDays() {
      axios
        .post("/getContractDaysStock", { contract_id: this.$route.params.id })
        .then(({ data }) => {
          this.days = data.data;
          this.stockLoader = false;
        });
    },
    formatDate(value) {
      return moment(value).format("DD- MM");
    },
    getWeekday(date) {
      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = date.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6
      return weekdays[dayIndex];
    },
    fetchRateCode() {
      axios
        .post("fetch_contract_from_season_rate_code_room_view", {
          contract_id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.rateCode = data.data;
          this.rateCodeLoader = false;
          this.rateCode.forEach((rate) => {
            const roomWithAdditionalProps = {
              loader: false,
              filterDays: "",
              b2c_weekend: "",
              b2c_weekday: "",
              b2b_weekend: "",
              b2b_weekday: "",
              fromDate: "",
              toDate: "",
              cost_weekday: "",
              cost_weekend: "",
              filterDaysArray: [],
            };
            Object.assign(rate, roomWithAdditionalProps);
            rate.rate_codes.forEach((rateCode) => {
              const roomWithAdditionalProps = {
                loader: false,
                filterDays: "",
                b2c_weekend: "",
                b2c_weekday: "",
                b2b_weekend: "",
                b2b_weekday: "",
                fromDate: "",
                toDate: "",
                cost_weekday: "",
                cost_weekend: "",
                filterDaysArray: [],
              };
              Object.assign(rateCode, roomWithAdditionalProps);
            });
          });
          // console.log(this.rateCode);
        });
    },
    //filter ratecode
    filterDaysMethod(room, param = "specific") {
      console.log("FromDate:", moment(room.fromDate).format("YYYY-MM-DD"));
      console.log("ToDate:", moment(room.toDate).format("YYYY-MM-DD"));
      const today = moment().startOf("day"); // Get the start of today

      if (param === "all") {
        // Update all rate codes
        room.rate_codes.forEach((rateCode) => {
          this.updateDaysForRateCode(room, rateCode, today);
        });
      } else {
        this.updateDaysForRoom(room, today);
      }
    },

    updateDaysForRateCode(room, rateCode, today) {
      rateCode.days.forEach((day) => {
        this.updateDayPrices(room, day, today);
      });
    },

    updateDaysForRoom(room, today) {
      room.days.forEach((day) => {
        this.updateDayPrices(room, day, today);
      });
    },

    updateDayPrices(room, day, today) {
      const dayDate = moment(day.date, "YYYY-MM-DD");

      if (dayDate.isSameOrBefore(today)) {
        return; // Skip updating if the day is today or before
      }

      switch (room.filterDays) {
        case "weekend":
          this.updateWeekendPrices(room, day);
          break;
        case "weekday":
          this.updateWeekdayPrices(room, day);
          break;
        case "specific":
          this.updateSpecificPrices(room, day);
          break;
        default:
          this.updateGeneralPrices(room, day);
          break;
      }
    },

    updateWeekendPrices(room, day) {
      if (day.is_weekend) {
        if (room.b2b_weekend) day.b2b_price = room.b2b_weekend;
        if (room.b2c_weekend) day.b2c_price = room.b2c_weekend;
        if (room.cost_weekend) day.cost = room.cost_weekend;
      }
    },

    updateWeekdayPrices(room, day) {
      if (!day.is_weekend) {
        if (room.b2b_weekday) day.b2b_price = room.b2b_weekday;
        if (room.b2c_weekday) day.b2c_price = room.b2c_weekday;
        if (room.cost_weekday) day.cost = room.cost_weekday;
      }
    },

    updateSpecificPrices(room, day) {
      const fromDate = moment(room.fromDate, "YYYY-MM-DD");
      const toDate = moment(room.toDate, "YYYY-MM-DD");
      const dayDate = moment(day.date, "YYYY-MM-DD");

      if (dayDate.isBetween(fromDate, toDate, null, "[]")) {
        this.updateGeneralPrices(room, day);
      }
    },

    updateGeneralPrices(room, day) {
      if (day.is_weekend) {
        if (room.b2b_weekend) day.b2b_price = room.b2b_weekend;
        if (room.b2c_weekend) day.b2c_price = room.b2c_weekend;
        if (room.cost_weekend) day.cost = room.cost_weekend;
      } else {
        if (room.b2b_weekday) day.b2b_price = room.b2b_weekday;
        if (room.b2c_weekday) day.b2c_price = room.b2c_weekday;
        if (room.cost_weekday) day.cost = room.cost_weekday;
      }

      // Use room.cost if available for both weekday and weekend
      if (room.cost) {
        day.cost = room.cost;
      }
    },
    stopDay(day, room_id) {
      const formData = new FormData();
      formData.append("season_rate_code_room_view_id", room_id);
      formData.append(`days[0][id]`, day.id);
      formData.append(`days[0][date]`, day.date);
      // formData.append(`days[0][is_stop]`, 1);
      axios
        .post(`/update_season_rate_code_room_view_day_stops`, formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          day.is_stop != day.is_stop;
          // this.fetchDays();
          this.fetchRateCode();
        });
      // console.log(room_id);
      // console.log(day);
    },
    updateDays(days, type, index) {
      // console.log(days);
      days.loader = true;
      if (type == 0) {
        // console.log(days);
        Swal.fire({
          title: this.$t("check_delete"),
          text: this.$t("are_you_sure_delete"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.$t("back"),
          confirmButtonText: this.$t("confirm_delete"),
        }).then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            formData.append("code", 0);
            formData.append("season_rate_code_room_views_id", days.id);
            axios
              .post(`/update_or_delete_room_view_days`, formData)
              .then(({ data }) => {
                Swal.fire({
                  title: "",
                  text: data.message,
                  icon: "success",
                });
                this.daysArray.splice(index, 1);
                this.fetchRateCodeData();
                days.loader = false;
              })
              .catch((error) => {
                Swal.fire({
                  title: "",
                  text: error.response.data.message,
                  icon: "error",
                });
                days.loader = false;
              });
          }
        });
      } else if (type == 1) {
        const formData = new FormData();
        formData.append("code", 1);
        formData.append(
          "season_rate_code_room_views_id",
          days.hotel_room_view_id,
        );
        days.days.forEach((day, index) => {
          Object.keys(day).forEach((key) => {
            formData.append(`days[${index}][${key}]`, day[key]);
          });
        });
        axios
          .post("/update_or_delete_room_view_days", formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            days.loader = false;
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            days.loader = false;
          });
      }
    },
    exportToExcel(data, filename) {
      if (filename == "days_stock") {
        // console.log(data);
        const daysData = data.days; // Extract days data from the object

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(daysData); // Convert days data to worksheet
        XLSX.utils.book_append_sheet(wb, ws, "Days Data");
        XLSX.writeFile(wb, "days_data.xlsx"); // Use provided filename or default to "days_data.xlsx"
      } else {
        axios
          .post("/getContractDaysStock", { contract_id: this.$route.params.id })
          .then(({ data }) => {
            let responseData = data.data;
            console.log(responseData);
            const headers = Object.keys(responseData[0]);

            const tableData = [
              // Header row
              headers.map((header) => this.$t(header)),
              // Data rows
              ...responseData.map((item) =>
                headers.map((header) => item[header]),
              ),
            ];

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet(tableData);

            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, "days_stock.xlsx");
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    },
  },
  mounted() {
    this.fetchDays();
    this.fetchRateCode();
  },
};
</script>

<style lang="scss" scoped>
@import "./seasons/_season.scss";
</style>
