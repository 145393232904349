<template>
  <section class="card_style mt-0">
    <div class="main_header_style">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
        <h4 class="mb-0">
          {{ $t("Policy") }}
        </h4>
      </div>
    </div>

    <form @submit.prevent="addPolicy()">
      <div class="row">
        <div class="col-lg-12 col-12">
          <div class="form-group">
            <label for="">{{ $t("description") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("description") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group mb-5"
                      v-for="(policy, index) in policy_contents"
                      :key="index"
                    >
                      <label for="description" class="form-label"
                        >{{ $t("description") }} ({{ policy.lang }})</label
                      >
                      <Editor
                        v-model="policy.description"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn PrimaryButton mt-4">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import Editor from "primevue/editor";
import axios from "axios";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "policyIndex",
  components: {
    Editor,
    AddFavDailog,
  },
  data() {
    return {
      languages: ["ar", "en", "id"],
      policy_contents: [],
      showButton: false,
    };
  },
  methods: {
    setPolicyContent() {
      this.languages.forEach((ele) => {
        this.policy_contents.push({
          lang: ele,
          description: "",
        });
      });
    },
    fetchPolicyData() {
      const formData = new FormData();
      if(this.$route.params.type === "portal") {
        formData.append("is_portal", 1);
      }
      axios.post(`/fetch_policy`, formData).then(({ data }) => {
        if (data.data.descriptions) {
          this.policy_contents = data.data.descriptions.map((desc) => {
            return {
              lang: desc.locale,
              description: desc.description,
            };
          });
          this.showButton = false;
        } else {
          this.showButton = true;
        }
      });
    },
    addPolicy() {
      let policyObj = {};
      this.policy_contents.forEach((ele) => {
        policyObj[`description_${ele.lang}`] = ele.description;
      });
      if(this.$route.params.type === "portal") {
        policyObj.is_portal = 1;
      }

      axios
        .post(`/website/policy`, policyObj)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          // window.location.reload();
          this.showButton = false;
        })
        .catch(({ error }) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.setPolicyContent();
    this.fetchPolicyData();
  },
};
</script>

<style></style>
