<template>
  <ul>
    <li
      v-for="(branch, index) in treeBranches"
      :key="index"
      :class="{ hidden: branch.deleted == true }"
    >
      <i class="fa-solid fa-spinner fa-spin plus" v-if="branch.loading"></i>
      <i
        v-else
        :class="{
          'fa-regular fa-square-plus': !branch.showChild,
          'fa-regular fa-square-minus': branch.showChild,
        }"
        class="plus"
        @click="fetchSubBranches(branch)"
      ></i>
      <div
        class="d-flex align-items-center justify-content-between px-4 branch_item"
      >
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="fetchSubBranches(branch)"
        >
          <span class="number">{{ branch.code }}</span>
          <span class="text d-inline-block mx-2">{{ branch.title_ar }}</span>
        </div>
        <div class="d-flex align-items-center flex-row">
          <p
            class="balance mb-0 mx-1"
            style="font-family: bold"
            v-tooltip.top="
              `${$t('pending_balance')}: ${Math.abs(branch.pending_balance)}`
            "
          >
            {{ Math.abs(branch.balance) }}
            <span>
              {{
                branch.balance >= 0
                  ? branch.balance_type == 1
                    ? $t("debtor")
                    : $t("creditor")
                  : branch.balance_type == 1
                    ? $t("creditor")
                    : $t("debtor")
              }}
            </span>
          </p>

          <div class="icons d-flex align-items-center justify-content-center">
            <i
              class="fa-solid fa-circle-plus mx-2"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              aria-controls="offcanvasBottom"
              @click="getParentChild(branch.id, branch)"
              v-if="branch.can_add_from_tree == 1"
            >
            </i>
            <i
              class="fa-solid fa-square-pen mx-2"
              data-bs-toggle="offcanvas"
              :data-bs-target="`#editOffcanvasBottom${branch.id}`"
              :aria-controls="`editOffcanvasBottom${branch.id}`"
              @click="get_branch(branch.id, branch)"
              v-if="branch.can_updated == 1"
            ></i>
            <!-- v-if="branch.can_updated == 1" -->
            <i
              class="fa-solid fa-trash mx-2 text-danger"
              @click="deleteBranch(branch)"
              v-if="branch.can_deleted == 1"
            ></i>
          </div>
          <div class="mx-1">
            <router-link :to="`/tree/branch/${branch.id}/0`">
              <i class="fa-solid fa-eye"></i>
            </router-link>
          </div>
        </div>

        <!-- {{branch.balance_type == 1 ? branch.balance > 0 ? $t("debtor") : $t("creditor") : branch.balance < 0 ? $t("creditor") : $t("debtor")}} -->
      </div>

      <editAccountTree
        @parentData="editData"
        :branch="branch"
        :parentID="branch.parent_id"
      />

      <getBranches
        v-if="branch.showChild"
        :treeBranches="branch.children"
        @getParentId="get_Parent_Id"
      />
    </li>
  </ul>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import editAccountTree from "./editAccountTree.vue";

export default {
  props: ["treeBranches"],
  emits: ["getParentId"],
  components: {
    editAccountTree,
  },
  data() {
    return {
      branch_id: null,
      currentBranch: {},
      deleted: false,
      branchID: "",
    };
  },
  methods: {
    fetchSubBranches(branch) {
      branch.loading = true;
      let parent_id = { parent_id: branch.id };
      axios.post("/fetch_tree_branches", parent_id).then(({ data }) => {
        branch.children = data.data.map((branch) => {
          return {
            ...branch,
            children: [],
            showChild: false,
            deleted: false,
          };
        });
        branch.loading = false;
        branch.showChild = !branch.showChild;
      });
    },
    get_branch(branch_id, branch) {
      this.currentBranch = branch; // close parent
      this.branchID = branch_id;
    },
    getParentChild(branchId, branch) {
      this.currentBranch = branch;
      this.$emit("getParentId", branchId);
      branch.showChild = false;
      // console.log("branch toggled => " , branch)
    },
    get_Parent_Id(branchId) {
      this.branch_id = branchId;
      this.getParentChild(branchId, this.currentBranch);
    },
    deleteBranch(branch) {
      let branchId = branch.id;
      const fromClick = true;
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`/tree_branch/${branchId}`, { fromClick })
            .then(({ data }) => {
              branch.deleted = true;
              branch = {};
              if (data.status == true) {
                Swal.fire("", data.message, "success");
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../view/dashboard/treeBranches/_tree.scss";
.hidden {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
